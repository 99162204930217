
import { TooltipFieldComponent } from "./../tooltip-field/tooltip-field.component";
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CreatePlanComponent } from "../../../components/create-plan/create-plan.component";
import { EspNoRowsOverlayComponent } from "../../../components/no-rows-overlay/no-rows-overlay.component";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { Observable, Subscription } from "rxjs";
import { DatePipe } from "@angular/common";
import {GridApi, IDatasource, IGetRowsParams, RowNode, SortModelItem} from "ag-grid-community";
import { Store, select } from "@ngrx/store";
import { Actions, ofType } from "@ngrx/effects";
import { GetPlanList, PlanListReceived, AppActionTypes, DeletePlans, DeletePlansSuccess, DeletePlansFailed, PlanListRetrieveFailed } from "src/app/store/actions";
import { DateRangeFieldComponent } from "../date-range-field/date-range-field.component";
import { TranslateService } from "@ngx-translate/core";
import { ModifiedDateFieldComponent } from "../modified-date-field/modified-date-field.component";
import { PlanLoadingOverlayComponent } from "../plan-loading-overlay/plan-loading-overlay.component";
import { EntityFieldComponent } from "../entity-field/entity-field.component";
import { InputTextComponent } from "src/app/components/input-text/input-text.component";
import { EntityTypeFieldComponent } from "../entity-type-field/entity-type-field.component";
import { Plan, Features } from "src/app/models/plan";
import { WfmModalComponent as modalComponent} from "src/app/components/wfm-modal/wfm-modal.component";
import { AuthenticationService } from "src/app/authentication.service";
import { DialogOptions } from "src/app/common/dialog-options";
import { CreatePlanComponentV2 } from "src/app/components/create-plan-v2/create-plan-v2.component";
import { PlanGen } from "../common/plan-param";
import { EspTableHeaderComponent } from "src/app/components/esp-table-header/esp-table-header.component";
import { GridItemActionComponent } from "src/app/components/grid-item-action/grid-item-action.component";
import { environment } from "src/environments/environment";
import {MswfRulesDialogComponent} from '../mswf-rules-dialog/mswf-rules-dialog.component';
import {CreatePlanCXoneComponent} from '../create-plan-cxone/create-plan-cxone.component';
import {TenantService} from '../../../tenant.service';

@Component({
  selector: "wfm-esp-plan-list-page",
  templateUrl: "./plan-list-page.component.html",
  styleUrls: ["./plan-list-page.component.scss"],
  providers: [MessageService]
})
export class PlanListPageComponent implements OnInit, OnDestroy {


  public gridApi: GridApi = null;
  public gridColumnApi: any;

  protected subscriptionList: Array<Subscription> = [];

  /* Grid Properties */
  public rowClassRules;
  public rowBuffer = 10;
  public rowSelection = "multiple";
  public rowModelType = "infinite";
  public paginationPageSize = 50;
  public cacheOverflowSize = 1;
  public maxConcurrentDatasourceRequests = 1;
  public infiniteInitialRowCount = 0;
  public maxBlocksInCache = 1;

  public loadingOverlayComponent = "planLoadingOverlay";
  public noPlanOverlayComponent = "noPlanOverlay";
  public noPlanOverlayComponentParams = {
    getOverlayMsg: () => {
      let msgKey = this.filterText ? "search.no.results.msg" : "manage.plan.no.plans";
      return this.translate.instant(msgKey)
    }
  }

  public frameworkComponents: any;
  public context;

  public currentGetRowParam: IGetRowsParams;
  public datasource: IDatasource;
  public rowData = [];

  public filterText: string;
  public planCreateSuccess = false; //flag

  public espPermission:EspPermission = EspPermission.VIEW;
  public locale : string = environment.defaultLocale;
  private isRowSelectable:any;
  private sortBy: any;
  private sortOrder: any;
  public  _isLoading = false;

  private readonly DEFAULT_SORT_MODEL:SortModelItem[] = [{ colId: "planName", sort: "asc" }];

  @ViewChild("searchTextBox") searchBoxEl: InputTextComponent;

  constructor(private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private store: Store<any>,
    private action$: Actions,
    private translate: TranslateService,
    private authService: AuthenticationService,
    public tenantService: TenantService) {

    this.context = {
      componentParent: this,
      isLoading: this.isLoading.bind(this),
      showMswfRulesDlg:this.showMswfRulesDlg.bind(this)
    };
    this.rowClassRules = {
      "row-disabled": (params) => this.disableRows(params),
      "row-view-only": (params) => this.isRowViewOnly(params)
    };
    this.isRowSelectable = function (rowNode) {
      return (rowNode.data && rowNode.data.permission === EspPermission.VIEW) ? false : true;
    };

  }

  ngOnInit() {
    this.frameworkComponents = {
      GridItemActionComponent: GridItemActionComponent,
      TooltipField: TooltipFieldComponent,
      DateRangeField: DateRangeFieldComponent,
      ModifiedDateField: ModifiedDateFieldComponent,
      EntityField: EntityFieldComponent,
      EntityTypeField: EntityTypeFieldComponent,
      noPlanOverlay: EspNoRowsOverlayComponent,
      planLoadingOverlay: PlanLoadingOverlayComponent,
      agColumnHeader: EspTableHeaderComponent
    };
    let state = this.store.select("state");
    state.subscribe((data) => {
      if (data.user){
        this.espPermission = data.user.espPermission;
      }
      if (data.locale) {
        this.locale = data.locale;
      }
    });
    this.addToSubscriptionList(this.activatedRoute.queryParams
      .subscribe(params => {
        this.sortBy = params['sortBy'] || null;
        this.sortOrder = params['sortOrder'] || null;
        this.setColumnDefsSort(this.sortBy, this.sortOrder);
      }));
    this.columnDefs.forEach(a => a.headerComponentParams =  {headerPrefix:  "plan.header."});
    //    this.filterRows("MSWF CT SET")
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.datasource = {rowCount: null, getRows: (params: IGetRowsParams) => this.getPlanList.call(this, params) };
    this.gridApi.setDatasource(this.datasource);
    this.initHandlers();
    this.setColumnVisibility();
    this.gridApi.sizeColumnsToFit();
  }

  public isLoading():boolean{
    return this._isLoading;
  }

  setColumnVisibility(){
    if (!this.hasEspModifyPermission) {
      this.gridColumnApi.setColumnVisible("multiSelect", false);
      this.gridColumnApi.setColumnVisible("actions", false);
    }
  }

  getPlanList(params: IGetRowsParams) {
    this.currentGetRowParam = params;
    this.startLoadingGrid();
    // console.log("Getting datasource rows, start: " + params.startRow + ", end: " + params.endRow);
    let blockSize = params.endRow - params.startRow;
    let columnId = "PLAN_NAME";
    let sortOrder = "ASC";
    if (params.sortModel.length > 0) {
      sortOrder = params.sortModel[0].sort.toUpperCase();
      columnId = ColumnId[params.sortModel[0].colId];
    }
    let payload = { offset: params.startRow, limit: blockSize, "sort-by": columnId, "sort-order": sortOrder, "plan-name-filter": this.filterText };
    this.store.dispatch(new GetPlanList(payload));
  }

  filterRows(text) {
      this.filterText = text;
      this.currentGetRowParam.startRow = 0;
      let sortModel = this.currentGetRowParam.sortModel;
      if(sortModel.length == 0)
      sortModel = this.DEFAULT_SORT_MODEL;
      this.gridColumnApi.applyColumnState({state:sortModel});
      this.gridApi.ensureIndexVisible(0, "top");
      this.gridApi.setDatasource(this.datasource);

  }

  deleteSelectedPlans() {
    this.deletePlans(this.selectedPlans);
  }

  deleteSinglePlan(plan: any){
    let plans = [plan];
    this.deletePlans(plans);
  }

  deletePlans(plans: any) {
    const planIds = plans.map((p: any) => p.planId);
    this.startLoadingGrid();
    this.store.dispatch(new DeletePlans(planIds));
  }

  showCreatePlanDlg() {
    const modalRef = this.modalService.open(CreatePlanComponent, {
      centered: true,
      windowClass: "b-info wfm-modal create-plan",
      container: "div.manage-plan-content",
      backdrop: "static",
      keyboard: false
    }).result.then((data) => {
      this.planCreatedAction(data);
    }, (reason) => {

    });
  }
  async showMswfRulesDlg(row) {
    const modalRef = this.modalService.open(MswfRulesDialogComponent, {
      centered: true,
      windowClass: "b-info wfm-modal mswf-rules",
      container: "div.manage-plan-content",
      backdrop: "static",
      keyboard: false
    });
    modalRef.componentInstance.mswfData = row.multiStepWorkflow;
    modalRef.componentInstance.planName = row.planName;

    await modalRef.result;
  }

  showCreateSitePlanDlg() {
    const modalRef = this.modalService.open(CreatePlanComponentV2, {
      centered: true,
      windowClass: "b-info wfm-modal create-plan",
      container: "div.manage-plan-content",
      backdrop: "static",
      keyboard: false
    }).result.then((data) => {
      this.startLoadingGrid();
      // this.planCreatedAction(data);
    }, (reason) => {

    });
  }

  createPlanSuccessHandler() {
    this.planCreateSuccess = true;
    this.searchBoxEl.clear();
    this.filterText = "";
    const currSortState = this.gridColumnApi.getColumnState().find(col => col.sort !== null);
    if (currSortState && currSortState.colId === "modifiedDate" && currSortState.sort === "desc") {
      this.startLoadingGrid();
      const startRow = this.currentGetRowParam.startRow;
      const blockSize = this.currentGetRowParam.endRow - startRow;
      let payload = { offset: startRow, limit: blockSize, "sort-by": ColumnId.modifiedDate, "sort-order": "DESC", "plan-name-filter": this.filterText };
      this.store.dispatch(new GetPlanList(payload));

    } else {
      const colIdArr = this.gridColumnApi.getColumns().map(col => col.colId);
      EspTableHeaderComponent.clearSortOrder();
      let sortModel = EspTableHeaderComponent.getSingleColumnSortModel("modifiedDate", "desc", colIdArr);
      this.gridColumnApi.applyColumnState({ state: sortModel });
    }
    this.gridApi.ensureIndexVisible(0, "top");
  };

  setColumnDefsSort(colId: ColumnId, sortOrder: String) {
    if (!colId || !sortOrder) {
      // default is plan name ascending
      this.columnDefs[1]["sort"] = "asc";
    } else {
      switch (colId) {
        case ColumnId.modifiedDate:
          this.columnDefs[4]["sort"] = sortOrder;
          break;
        default:
          // plan name ascending is default
          this.columnDefs[1]["sort"] = "asc";
      }
    }
  }

  createPlanErrorHandler(resp: any) {
    console.log("ERROR:", resp);
    this.endLoadingGrid();
    if (resp.status === 401 || resp.status === 500) {
      this.unrecoverableErrorHandler(resp);
    } else {
      let failMsg = this.translate.instant("plan.create.failure");
      this.messageService.add({ severity: "error", detail: failMsg });
    }
  }

  planCreatedAction(obj: Observable<any>) {
    this.startLoadingGrid();
    obj.subscribe(this.createPlanSuccessHandler.bind(this), this.createPlanErrorHandler.bind(this));
  }

  performCellClick(event) {
    if (ColumnId[event.colDef.colId] && event.data && event.data.status.toLowerCase() === "active") {
      let hasV5GridFeature = this.authService.hasFeature(Features.V5_GRID);
      let isCXOneWfm = this.authService.userData.idpType === "CXONE_WFM";
      let sitePlansVersion = hasV5GridFeature || isCXOneWfm ? "site-plans-v5" : "site-plans-v4";
      let urlRoot = event.data.planGeneration == PlanGen.V2  ?  sitePlansVersion : "plans";
      this.router.navigate([{ outlets: { primary: [urlRoot, event.data.planId] } }]);
    }
  }

  get selectedPlans() {
    if (this.gridApi !== null) {
      return this.gridApi.getSelectedNodes().map(item => item.data);
    }
    return null;
  }

  get selectedPlanCount() {
    if (this.gridApi !== null) {
      const selectedNodes = this.gridApi.getSelectedNodes();
      return selectedNodes && selectedNodes !== null && selectedNodes.length > 0 ? selectedNodes.length : 0;
    }
    return 0;
  }

  get hasSelectedPlan() {
    return this.selectedPlanCount > 0;
  }

  get hasEspModifyPermission() {
    return this.espPermission === EspPermission.MODIFY;
  }


  disableRows(params: any) {
    if (params && params.data) {
      return params.data.status.toLowerCase() !== "active";
    }
    else return false;
  }

  isRowViewOnly(params) {
    if (params && params.data && params.data.permission && !this.disableRows(params)) {
      return params.data.permission.toLowerCase() === "view";
    }
    return false;
  }

  private prevData: any = { plans: [], lastRow: 0 };

  planListReceiveHandler(action: PlanListReceived) {
    if(action.requestPayload && action.requestPayload["plan-name-filter"]!= this.filterText){
      return;
    }

    let data = action.payload;
    var lastRow = -1;
    if (data.totalCount <= this.currentGetRowParam.endRow) {
      lastRow = data.totalCount;
    }
    // Checks the data and make sure that the return is an array for the grid to process properly
    let plans = !data.plans || data.plans === null ? [] : data.plans;
    if (data.offset == 0) { //it's the first page
      this.prevData = { plans, lastRow };
    } else {
      this.prevData = { plans: [...this.prevData.plans, ...plans], lastRow }; //append to previous plans
    }

    this.currentGetRowParam.successCallback(plans, lastRow);
    this.endLoadingGrid();
    // In case we have no plan, we want to show no row overlay
    if (Array.isArray(plans) && plans.length === 0) {
      this.gridApi.showNoRowsOverlay();
    }

    /*Show toast if GetPlanList triggered by create plan*/
    if (this.planCreateSuccess) {
      this.planCreateSuccess = false;
      let successMsg = this.translate.instant("plan.create.success");
      this.messageService.add({ severity: "success", detail: successMsg });
    }
  }

  planListRetrieveFailHandler(action: PlanListRetrieveFailed) {
    console.log("ERROR retrieving plan list");
    if (this.prevData.plans.length > 0) {
      let plans = this.prevData.plans.slice(0, 100); //100 is the block size
      this.currentGetRowParam.successCallback(plans, plans.length);
    } else {
      this.currentGetRowParam.successCallback([], 0);
    }
    this.endLoadingGrid();
    let failMsg = this.translate.instant("plan.retrieve.failed");
    this.messageService.add({ severity: "error", detail: failMsg });
  }

  deletePlansSuccessHandler(action: DeletePlansSuccess) {
    if (action.payload) {
      let deletedPlansResp = action.payload.deletedPlans;
      let succeeded: Array<string> = deletedPlansResp.SUCCESS;
      let failed = deletedPlansResp.FAILED;
      let sortModel = this.currentGetRowParam.sortModel;
      if(sortModel.length == 0)
        sortModel = this.DEFAULT_SORT_MODEL;
      this.gridColumnApi.applyColumnState({state:sortModel});
      // this.gridApi.deselectAll();
      // this.gridApi.refreshInfiniteCache();
      this.gridApi.ensureIndexVisible(0, "top");
      this.gridApi.setDatasource(this.datasource);
      if ((failed == null || failed.length == 0) && succeeded.length > 0) {
        let plansDeleted = (succeeded.length == 1) ? "single" : "multiple";
        let successMsg = this.translate.instant("plan.delete.success." + plansDeleted);
        this.messageService.add({ severity: "success", detail: successMsg });
      }
      else if (failed.length > 0) {
        let failMsg = this.translate.instant("plan.delete.failed");
        this.messageService.add({ severity: "error", detail: failMsg });
      }
    }
    this.endLoadingGrid();

  }

  deletePlansFailureHandler(action: DeletePlansFailed){
    const failMsg = this.translate.instant("plan.retrieve.failed");
    this.messageService.add({ severity: "error", detail: failMsg });
    this.endLoadingGrid();
  }

  ltfRetrieveFailHandler(){
    const logout = () => {
      if(this.router.navigate(["/login"]))
        this.authService.logout();
    };
    const dialogOpt:DialogOptions = new DialogOptions();
    dialogOpt.titleKey = "generic.err.title";
    dialogOpt.messageKey = "generic.err.msg";
    dialogOpt.msgType = "error";
    dialogOpt.confirmLabel = "btn.signin";
    dialogOpt.showCancel = false;
    modalComponent.showModalMessage(dialogOpt, this.modalService).then(logout, logout);
  }

  unrecoverableErrorHandler(error:any) {
    this.modalService.dismissAll();
    const done = ()=>{};
    const dialogOpt:DialogOptions = new DialogOptions();
    dialogOpt.titleKey = "generic.err.title";
    dialogOpt.messageKey = "unrecoverable.err.msg";
    dialogOpt.msgType = "error";
    dialogOpt.confirmLabel = "btn.close";
    dialogOpt.showCancel = false;
    modalComponent.showModalMessage(dialogOpt, this.modalService).then(done, done);
  }

  startLoadingGrid(){
    this.gridApi.showLoadingOverlay();
    this._isLoading = true;
  }

  endLoadingGrid(){
    this.gridApi.hideOverlay();
    this._isLoading = false;
  }

  setDefaultSorting(param:any){
    EspTableHeaderComponent.setSortOrder("planName", "asc");
  }


  initHandlers() {
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SortingCleared))
        .subscribe(this.setDefaultSorting.bind(this))
    );
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.UnrecoverableError))
        .subscribe(this.unrecoverableErrorHandler.bind(this))
    );
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.PlanListReceived))
        .subscribe(this.planListReceiveHandler.bind(this))
    );
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.PlanListRetrieveFailed))
        .subscribe(this.planListRetrieveFailHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.DeletePlansSuccess))
        .subscribe(this.deletePlansSuccessHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.DeletePlansFailed))
        .subscribe(this.deletePlansFailureHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.LTFRetrieveFail))
      .subscribe(this.ltfRetrieveFailHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.CreatePlanSubmitted))
      .subscribe(this.createPlanSuccessHandler.bind(this))
    );
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.CreatePlanError))
      .subscribe(this.createPlanErrorHandler.bind(this))
    );
  }

  protected addToSubscriptionList(newSubscription: Subscription) {
    this.subscriptionList.push(newSubscription);
  }

  private clearSubscriptionList() {
    if (this.subscriptionList.length > 0) {
      this.subscriptionList.forEach(subscriptionItem => subscriptionItem.unsubscribe());
      this.subscriptionList = null;
    }
  }

  public ngOnDestroy() {
    this.clearSubscriptionList();
  }

  checkBoxCellClass = (p:any) => {
    if(this.hasEspModifyPermission)
      return (p.data && p.data.permission === EspPermission.VIEW) ?  'checkbox-disabled': '';
    else
      return 'checkbox-hidden';
  }

  icons = {
    checkboxDisabled: `<svg class="icon"><use xlink:href="#icon-rect-disabled" /></svg>`,
    checkboxCheckedReadOnly: `<svg class="icon"><use xlink:href="#icon-rect-readonly" /></svg>`,
    sortAscending: `<svg class="icon"><use xlink:href="#icon-sort-ascending" /></svg>`,
    sortDescending: '<svg class="icon"><use xlink:href="#icon-sort-descending" /></svg>'
  };
  defaultColDef={
    sortable:true
  }
  columnDefs:any = [

    {
      headerName: null,
      field: null,
      checkboxSelection: () => this.hasEspModifyPermission,
      cellClass: this.checkBoxCellClass.bind(this),
      headerCheckboxSelection: false,
      suppressSizeToFit: true,
      width: 50,
      headerTooltip: " "
    },
    {
      headerName: this.translate.instant("plan.header.planName"),
      field: "planName",
      colId: "planName",
      //autoHeight: true,
      cellRenderer: "TooltipField",
      minWidth:150,
      cellClass(params) {
        if(params.data && params.data.permission && params.data.status.toLowerCase() === "active") {
          if (params.data.permission.toLowerCase() === "view") {
            return "cell-view-only-name";
          }
        }
        return "";
      }
    },
    {
      headerName: this.translate.instant("plan.header.createdBy"),
      field: "createdBy",
      colId: "createdBy",
      //autoHeight: true,
      cellRenderer: "TooltipField",
      minWidth:100,
    },
    {
      headerName: this.translate.instant("plan.header.dateRange"),
      colId: "dateRange",
      cellRenderer: "DateRangeField",
      cellRendererParams: () => (environment.locales.find(l => l.locale === this.locale)),
      suppressSizeToFit: true,
      width: 200,
      //autoHeight: true
    },
    {
      headerName: this.translate.instant("plan.header.modifiedDate"),
      field: "modifiedTime",
      colId: "modifiedDate",
      cellRenderer: "ModifiedDateField",
      cellRendererParams: () => (environment.locales.find(l => l.locale === this.locale)),
      minWidth:100
    },
    {
      headerName: this.translate.instant("plan.header.entityType"),
      field: "entityType",
      colId: "entityType",
      cellRenderer: "EntityTypeField",
      minWidth:100
    },
    {
      headerName: this.translate.instant("plan.header.entity"),
      field: "entityName",
      colId: "entity",
      cellRenderer: "EntityField",
      minWidth:150
    },
    {
      headerName: this.translate.instant("plan.header.forecastName"),
      field: "ltfcstName",
      colId: "forecastName",
      //autoHeight: true,
      cellRenderer: "TooltipField",
      minWidth:150
    },
    {
      headerName: this.translate.instant("plan.header.actions"),
      colId: "actions",
      sortable: false,
      headerClass: "text-center only-action",
      suppressSizeToFit: true,
      width: 100,
      //pinned: "right",
      cellRendererSelector: (p:any)=>(p.data && p.data.permission === EspPermission.VIEW)
      ? null :
      {
        component: 'GridItemActionComponent',
        params: {
          callback: this.deleteSinglePlan.bind(this),
          messageDelete: this.translate.instant("plan.delete.single")
        }
      }
    }
  ];

  showCreateCxonePlanDlg() {
    const modalRef = this.modalService.open(CreatePlanCXoneComponent, {
      centered: true,
      windowClass: "b-info wfm-modal create-plan",
      container: "div.manage-plan-content",
      backdrop: "static",
      keyboard: false
    }).result.then((data) => {
      console.log("submit CXone plan creation request");
    }, (reason) => {
      console.debug("dismissed CXone create plan dialog");
    });
  }

  showIexOrCxonePlanDlg() {
    if (this.tenantService.isCXOne) {
      this.showCreateCxonePlanDlg();
    } else {
      this.showCreateSitePlanDlg()
    }
  }
}

export enum ColumnId {
  planName = "PLAN_NAME",
  createdBy = "CREATED_BY",
  modifiedDate = "MODIFIED_DATE",
  entityType = "ENTITY_TYPE",
  entity = "ENTITY",
  forecastName = "FORECAST_NAME",
  dateRange = "DATE_RANGE"
}

export enum EspPermission {
  MODIFY = "MODIFY",
  VIEW = "VIEW"
}



