import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Component, HostListener, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ColumnApi, GridApi, IDatasource, IRowNode} from "ag-grid-community";
import {format} from "date-fns";
import {PlanDetailHeaderRowComponent} from "../plan-detail-header-row/plan-detail-header-row.component";
import {
  PlanDetailClickableHeaderComponent
} from "../plan-detail-clickable-header/plan-detail-clickable-header.component";
import {BasePage} from "../../base-page";
import {TranslateService} from "@ngx-translate/core";
import {Store} from "@ngrx/store";
import {saveAs} from 'file-saver';
import {Actions, ofType} from "@ngrx/effects";
import {Observable, Subscription} from "rxjs";
import {
  AppActionTypes,
  ApplyFTERequired,
  ApplyMuParams,
  EditPlanDetail,
  EditPlanDetailReceived,
  ExportEveryCt,
  ExportEveryCtFailed,
  ExportEveryCtSuccess,
  GetPlanDetail, GetPlanDetailV2,
  LoadPlanDetail, LoadPlanDetailV2,
  MultiEditPlanDetail, MultiEditPlanDetailV2,
  PlanDetailReceived,
  PlanDetailReceivedV2,
  PlanNotExist,
  SavePlanDetail,
  SavePlanSettings
} from "src/app/store/actions";
import {PlanLoadingOverlayComponent} from "../plan-loading-overlay/plan-loading-overlay.component";
import {DatePipe, TitleCasePipe} from "@angular/common";
import {NumericEditorComponent} from "../common/cell-editors/numeric/numeric-editor.component";
import {
  CachedMU,
  EditPlanDetailRequestDTO,
  EntityType,
  Features,
  LRUCache,
  MultiEditPlanDetailRequestDTO, MultiEditPlanDetailRequestDTOV2,
  PlanDetailResponse, PlanDetailResponseV2, SelectedMU
} from "src/app/models/plan";
import {ValidatorFactory} from "../common/validator-factory";
import {PlanCellType,  PlanParam} from "../common/plan-param";

import {MessageService} from "primeng/api";
import {NgbDropdownConfig, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {WfmModalComponent as modalComponent} from "src/app/components/wfm-modal/wfm-modal.component";
import {ConfirmationMsgFactory} from "../common/confirm-msg-factory";
import {DialogOptions} from "../../../common/dialog-options";
import {ConvertPlanDialogComponent} from "../convert-plan-dialog/convert-plan-dialog.component";
import {PlanSaveAsComponent} from "../plan-save-as/plan-save-as.component";
import {AuthenticationService} from "../../../authentication.service";
import {PlanHelper} from "../../../helpers/plan-helper";
import {ColumnId} from "../plan-list-page/plan-list-page.component";
import {filter, take} from "rxjs/operators";
import {BackgroundTask} from "../../../store/application-state";
import {WfmSelectComponent} from "src/app/components/wfm-select/wfm-select.component";
import {PlanSettingsDialogComponent} from "../plan-settings-dialog/plan-settings-dialog.component";
import {CTDistributionCalcMethod, PlanContactTypes} from "../../../common/enums";
import {NumberUtils} from "../../../common/utils/number.utils";
import {environment} from "../../../../environments/environment";
import {ModelUpdatedEvent} from "@ag-grid-enterprise/all-modules";
import {DateUtils} from "../../../common/utils/date";
import {MswfRulesDialogComponent} from "../mswf-rules-dialog/mswf-rules-dialog.component";
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {RangeSelectionModule} from '@ag-grid-enterprise/range-selection';
import {RowGroupingModule} from '@ag-grid-enterprise/row-grouping';
import {EnterpriseCoreModule} from '@ag-grid-enterprise/core';
import {PlanDetailValidationCellComponent} from '../plan-detail-validation-cell/plan-detail-validation-cell.component';

import {DebounceUtil} from '../../../helpers/debounce';
import {MultiSelect} from 'primeng/multiselect';
import {EspNoRowsOverlayComponent} from '../../../components/no-rows-overlay/no-rows-overlay.component';
import {MuGroupHeaderComponentV2} from '../../../components/mu-group-header-v2/mu-group-header-v2.component';
import {NewHireDialogV2Component} from '../new-hire-dialog-v2/new-hire-dialog-v2.component';
import {PlanSettingsCtDialogV2Component} from '../plan-settings-ct-dialog-v2/plan-settings-ct-dialog-v2.component';
import { TenantService } from "../../../tenant.service";

export enum DetailViewMode {
  Daily = "DAY",
  Weekly = "WEEK",
  Monthly = "MONTH"
}
export enum ParamPermission {
    Modify = "MODIFY",
    ReadOnly = "READ"
}

@Component({
  selector: "plan-detail-v5",
  templateUrl: "./plan-detail-v5.component.html",
  styleUrls: ["./plan-detail-v5.component.scss"],
  providers: [MessageService, NgbDropdownConfig, TitleCasePipe]
})
export class PlanDetailComponentV5 extends BasePage implements OnInit, OnDestroy {

  @ViewChild('ctSelector') ctSelector: MultiSelect;
  @ViewChild('muSelector') muSelector: MultiSelect;

  public dateLocale = DateUtils.getDatefnsLocale("en");
  public readonly ERROR_TOAST_LIFE = 4000;
  private pinnedRightColumnWidth = 105;
  private pinnedRightTotalColumnWidth = 115;
  private pinnedLeftColumnWidth = 225;
  private dateColumnWidth = 110;
  private dateShortFormat = "M/d";
  public  _isLoading = true;
  public _dataIsSaved = true;
  private _userHasModify = false;

  public planId: string = null;
  public gridApi: GridApi = null;
  public gridParams: any;
  protected subscriptionList: Array<Subscription> = [];
  public datasource: IDatasource;
  public frameworkComponents: any;
  public isChartView=false;
  public chartsCount=0;
  public planName: String;
  public planStartDate: String;
  public planEndDate: String;
  public contactTypeList: any = [];
  private managementUnitsList: any = [];
  public selectedContactType:any = null;

  public static readonly ALL_CONTACT_TYPES:string = "ALL_CONTACT_TYPES";
  public static readonly MANAGEMENT_UNITS:string = "MANAGEMENT_UNITS";
  public planEntityType:any = null;
  public entityId: any;
  public entityName: String;
  public ltForecastName: String;
  public task:BackgroundTask = null;//{progress:55,status:TaskState.InProgress};
  public isPlanLoaded:boolean;
  public _isFTERequiredDirty = false;
  public suppressLoadingOverlay = true; //we don't want to display spinner when plan is loading
  protected translationKeys = ["plan.label.param", "plan.label.total", "plan.label.average", "date.format.short"];
  @ViewChild("grid")
  private grid;
  public isRenderingData = false;
  @ViewChild("muctSelector") muctSelector: WfmSelectComponent;

  colDefs = [];
  colDefsOld = [];

  data = null;
  rowData = null;
  topRowsData = null;
  allRowsData = null;

  rowDataDaily = [];

  rowDataMonthly = [];

  rowDataWeekly = [];

  private weekStartDate = 0;
  public language = "en";
  public locale = "en-US";
  public username = null;
  public userInfo$: Observable<string>;
  public loadingOverlayComponent = "planLoadingOverlay";
  private _isNestedRoute:boolean;

  public _planCtSettingsFeature:boolean = false;

  private _isMUDirty:boolean = false;
  private columnApi: ColumnApi = null;
  private planSettings: any = {};
  private localParams : any = PlanParam;
  private planContactTypes: PlanContactTypes = PlanContactTypes.NA;
  private scrollTargetColumn: any;
  private scrollTargetLeft: any;
  public multiStepWorkflow: any;
  private modules = [ClipboardModule, RangeSelectionModule, EnterpriseCoreModule, RowGroupingModule]
  private gridOptions: any;

  private editingContext: any;
  private lastEditedCell: any;
  public _dirtyCells = new Set();
  public _invalidCells = new Set();
  private _isGridValid: boolean = true;
  public _isRollupCollapsed = false;
  private _isPristine: boolean;
  private rowClassRules = null;
  private cellClassRules = null;
  private groupRowRendererParams: any;
  defaultColDef = null;
  private selectedCTs: any = [];
  public selectedMUs: any = [];
  private isManualEditing: boolean = false;
  public currentEntityView: MUCTKey = MUCTKey.CT
  private shouldRefreshMUs: boolean = false;
  private entitySettings: any;
  public cacheSelectedMUs: any = [];

  public readonly cachedMUsKey = "selectedMUs";

  public get isCTView(){
    return this.currentEntityView === MUCTKey.CT
  }
  public get isMUView(){
    return this.currentEntityView === MUCTKey.MU
  }
  public get isGridInvalid(): boolean {
    return !this._isGridValid;
  }
  get disableEntityViewToggle(){
    return this._isMUDirty || this._isFTERequiredDirty
  }

  get hasRows(){
    return this.rowData?.length>0;
  }
  constructor(
    private translateSrv: TranslateService,
    private store: Store<any>,
    private messageService: MessageService,
    private action$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    public modalService: NgbModal,
    private ngbDropdownConfig: NgbDropdownConfig,
    private titleCasePipe: TitleCasePipe,
    private authService: AuthenticationService,
    public tenantService: TenantService) {

    super(translateSrv);

    this._planCtSettingsFeature = authService.hasFeature(Features.PLAN_CT_SETTINGS);

    this.groupRowRendererParams = {
      innerRenderer: MuGroupHeaderComponentV2,
      suppressCount:true
    }
    ngbDropdownConfig.placement = "bottom-right";

    this.updateGridValid = DebounceUtil.debounce(this.updateGridValid.bind(this),100);

    this.userInfo$ = this.store.select("state");
    this.userInfo$.subscribe((value: any) => {
      if(!value) return;
      this.setUsernameLocale(value);

    });

  }
  public setUsernameLocale(value: any) {
    if (value.user) this.username = value.user.username;
    if (value.locale) this.locale = value.locale;
    if (value.user && value.user.language) {
      if (this.language != value.user.language) {
        this.dateLocale = DateUtils.getDatefnsLocale(value.user.language);
        this.language = value.user.language;
      }
    }
  }

  get UnappliedChanges(){
    return this._isLoading || this._isMUDirty || this._isFTERequiredDirty || this._isGridValid==false
  }

  isDisableExportAsCsv():boolean {
    return (this.planSettings && this.planSettings.ctDistributionCalcMethod == CTDistributionCalcMethod.FTE_REQUIRED)
      && this._isFTERequiredDirty;
  }

  isDisableExportEveryCt():boolean {
    let isDisable = false;

    if (this.currentEntityView == MUCTKey.MU) {
      isDisable = !this.isMUApplyDisabled;
    } else {
      isDisable = this.isDisableExportAsCsv();
    }

    return isDisable;
  }

  async showConvertToActiveDialog(){

    if(this._dataIsSaved) {
      try {
        const modalRef = this.modalService.open(ConvertPlanDialogComponent, {
          centered: true,
          windowClass: "b-info wfm-modal",
          container: "div.plan-detail-container",
          backdrop: "static",
          keyboard: false
        });

        modalRef.componentInstance.planStartDate = this.planStartDate;
        modalRef.componentInstance.planEndDate = this.planEndDate;
        modalRef.componentInstance.planName = this.planName;
        modalRef.componentInstance.planId = this.planId;
        await modalRef.result;
      }catch(e){

      }
    }else{
      let dgOption: DialogOptions = new DialogOptions();
      dgOption.titleKey = "plan.convert.unsaved.dialog.title";
      dgOption.messageKey = "plan.convert.unsaved.dialog.msg";
      dgOption.msgType = "warn";
      dgOption.showCancel = true;
      dgOption.confirmLabel = "btn.confirm.label";
      try{
        let result = await modalComponent.showModalMessage(dgOption, this.modalService);
        this.action$.pipe(
          ofType(AppActionTypes.SavePlanDetailComplete),
          take(1))
          .subscribe((action:any)=>{
            if(action.payload !== null){
              this.showConvertToActiveDialog();
            }

          });
        this.savePlan();
      }catch(e){
        //user canceled
      }


    }
  }


  async showMswfRulesDlg() {
    try {
      const modalRef = this.modalService.open(MswfRulesDialogComponent, {
        centered: true,
        windowClass: "b-info wfm-modal mswf-rules",
        container: "div.plan-detail-container",
        backdrop: "static",
        keyboard: false
      });
      modalRef.componentInstance.mswfData = this.multiStepWorkflow;
      modalRef.componentInstance.planName = this.planName;
      modalRef.componentInstance.displayPlanName = false;


      await modalRef.result;
    } catch (e) {
      console.log(e);
    }
  }

  get isMUgrid(){
    return this.currentEntityView==MUCTKey.MU;
  }

  get isMUApplyDisabled(){
    return this._isLoading || !this._isMUDirty;
  }

  get isWorkloadCTgrid(){
    return this.currentEntityView==MUCTKey.CT
          && this.planSettings?.planContactTypes === PlanContactTypes.WORKLOAD;
  }

  get CTDistMode():CTDistributionCalcMethod{
    if(this.planSettings) {
      return this.planSettings.ctDistributionCalcMethod;
    }else{
      return null;
    }
  }
  setMUGridDirty(dirty:boolean){
    this._isMUDirty = dirty;
    const ctl = [...this.contactTypeList];
    if(dirty){
      //disable ct selections
      for (let index = 0; index < ctl.length; index++) {
        const e = ctl[index];
        if(e.type != MUCTKey.MU){
          e.disabled = true;
        }
      }
    }
    else{
      //enable ct selections
      for (let index = 0; index < ctl.length; index++) {
        const e = ctl[index];
        e.disabled = false;
      }
    }
    this.contactTypeList = ctl;

  }

  applyMUEdits(){
    console.debug("applying MU edits");
    this._isLoading = true;
    //this.gridApi.showLoadingOverlay();
    this.store.dispatch(new ApplyMuParams({planId: this.planId}));
  }

  applyFTERequired(){
    console.debug("applying FTE required");
    this.store.dispatch(new ApplyFTERequired({planId: this.planId}));
  }

  gatherCurrentSettings(): any {
    return {
      ctDistributionCalcMethod: this.planSettings.ctDistributionCalcMethod,

      usePatienceTime: this.planSettings.medianPatienceTime == null ? false : true,
      medianPatienceTime: this.planSettings.medianPatienceTime == null ? null : String(this.planSettings.medianPatienceTime),
      planSettingsWorkloadType: this.planSettings.planSettingsWorkloadType == null ? PlanContactTypes.NA : this.planSettings.planSettingsWorkloadType
    }
  }

  showPlanSettingsDialog() {
    this._showPlanSettingsDialog(this.gatherCurrentSettings(), false);
  }

  _showPlanSettingsDialog(settings, isDirty) {
    const modalRef = this.modalService.open(PlanSettingsDialogComponent, {
      centered: true,
      windowClass: "b-info wfm-modal",
      container: "div.plan-detail-container",
      backdrop: "static",
      keyboard: false
    });

    modalRef.componentInstance.reset(settings, isDirty, this.gatherCurrentSettings());

    modalRef.result.then((data) => {
      this.store.dispatch(new SavePlanSettings({planId: this.planId,planSettings:data}));
    }, (reason) => {

    });
  }



  showNewHireSettingsDialog(mu) {
    const modalRef = this.modalService.open(NewHireDialogV2Component, {
      centered: true,
      windowClass: "b-info wfm-modal",
      container: "div.plan-detail-container",
      backdrop: "static",
      keyboard: false
    });

    modalRef.componentInstance.muSettings = this.entitySettings[mu];
    modalRef.componentInstance.muOid = mu;
    modalRef.componentInstance.planVersion = 2;

    modalRef.result.then((newHireTrainingWeeks) => {
      this._dataIsSaved = false;
      this.entitySettings[mu] = {...this.entitySettings[mu],newHireTrainingWeeks}
    }, (reason) => {

    });
  }

  showCtSettingsDialog() {
    const modalRef = this.modalService.open(PlanSettingsCtDialogV2Component, {
      centered: true,
      windowClass: "b-info wfm-modal",
      container: "div.plan-detail-container",
      backdrop: "static",
      keyboard: false
    });

    let entityOid = this.getSelectedEntityOid();
    modalRef.componentInstance.planId = this.planId;
    modalRef.componentInstance.interval = this.gridContext.viewMode
    modalRef.componentInstance.entitydOid = entityOid;
    modalRef.componentInstance.entitySettings = this.entitySettings[entityOid];

    modalRef.result.then((data) => {
      this._dataIsSaved = false;
      this.entitySettings[entityOid] = {...this.entitySettings[entityOid],startingBacklog:data};
    }, (reason) => {

    });
  }

  async showSaveAsDlg() {
    try {
      const modalRef = this.modalService.open(PlanSaveAsComponent, {
        centered: true,
        windowClass: "b-info wfm-modal",
        container: "div.plan-detail-container",
        backdrop: "static",
        keyboard: false
      });
      let component = modalRef.componentInstance;
      component.planId = this.planId;
      component.entityType = this.planEntityType;
      component.entityId = this.entityId;
      component.entityName = this.entityName;
      component.ltfcstName = this.ltForecastName;
      component.planStartDate = this.planStartDate;
      component.planEndDate = this.planEndDate;

      await modalRef.result;
    } catch(error) {
      if (error) {
        console.log("error during showSaveAsDlg: " + error.message);
      }
    }
  }

  public gridContext: any = {
    viewMode: DetailViewMode.Monthly,
  };

  //commented due to popover flickering
  // get gridContext() {
  //   return {updateViewMode: this.updateViewMode.bind(this)}
  // }

  public updateViewMode(isCollapsed,column=null) {
    if(column){
      this.setScrollTarget(isCollapsed,column);
    }
    if(this.isLoading()){
      return;
    }

    let newMode: DetailViewMode = null;
    switch (this.gridContext.viewMode) {
      case DetailViewMode.Daily:
        if (isCollapsed) {
          newMode = DetailViewMode.Weekly;
        }
        break;
      case DetailViewMode.Weekly:
        if (isCollapsed) {
          newMode = DetailViewMode.Monthly;
        } else {
          newMode = DetailViewMode.Daily;
        }
        break;
      case DetailViewMode.Monthly:
        if (!isCollapsed) {
          newMode = DetailViewMode.Weekly;
        }
        break;
    }

    this.getPlanDetail(newMode);

  }

  ngOnInit() {
    super.ngOnInit();
    this.frameworkComponents = {
      // agColumnHeaderGroup: PlanDetailHeaderRowComponent,
      // agColumnHeader: PlanDetailClickableHeaderComponent,
      planLoadingOverlay: PlanLoadingOverlayComponent,
      numericEditor: NumericEditorComponent,
      planDetailValidationCell:PlanDetailValidationCellComponent,
      muGroupHeader: MuGroupHeaderComponentV2,
      noRowsOverlay: EspNoRowsOverlayComponent,
    };

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(()=>{
      this.updateCurrentRoute();
    });

    this.route.params.subscribe(params => {
      this.planId = params["id"];
    });
    this.translationResults.subscribe((result) => {
      this.updateTranslationObj(result);
      if (this.grid && this.grid.api) {
        this.grid.api.refreshHeader();
      }
    });

    this.gridContext = {
      updateViewMode: this.updateViewMode.bind(this),
      showMessage: this.showMessage.bind(this),
      viewMode: this.gridContext.viewMode,
      isLoading: this.isLoading.bind(this),
      cellEditable: this.cellEditable.bind(this),
      setCustomValue:this.setCustomValue.bind(this),
      canDrillDown:this.canDrillDown.bind(this),
      isGridValid: () => this._isGridValid,
      isValidCell: (row, field) => {
        return this._invalidCells.has(this.getCellID(row)) === false;
      },
      getValidatorMessage(param) {

      },
      planSettings:()=>this.planSettings,
      entitySettings:()=>this.entitySettings,
      showNhtpDialog:(mu)=>this.showNewHireSettingsDialog(mu),
      isRollupCollapsed:()=>this._isRollupCollapsed,
      toggleRollupGroup: ()=>{
        const value = this._isRollupCollapsed=!this._isRollupCollapsed;
        this.storeMUSelectionList();
        return value;
      }
    };
    this.initGridOptions();
    this.initHandlers();
    this.initRowClassRules();
    this.initCellClassRules();
    this.fetchMUSelectionList();
    this.getPlanDetail(this.gridContext.viewMode);
    this.updateCurrentRoute();

    this.defaultColDef = {
      cellRenderer:"planDetailValidationCell",
      cellClassRules:this.cellClassRules
    }
  }

  private initLRUCache() {
    const cachedMUs: LRUCache = new LRUCache();
    cachedMUs.set(this.planId, new CachedMU(new Array<SelectedMU>()));
    localStorage.setItem(this.cachedMUsKey, cachedMUs.asString());
    return cachedMUs;
  }

  ngOnDestroy(): void {
    this.clearSubscriptionList();
    PlanHelper.removePlanDetailSessionId(this.planId);
  }

  public isLoading():boolean{
    return this._isLoading;
  }

  public noRowsComponentParams = {
    getOverlayMsg: () => {
      let msgKey = "plan.details.no.mus";
      return this.translateSrv.instant(msgKey);
    },
    image:'2'
  };
  async showMessage(titleKey, msgKey, type) {

    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = titleKey;
    dgOption.messageKey = msgKey;
    dgOption.msgType = type;
    dgOption.showCancel = false;
    dgOption.confirmLabel = "btn.ok.label";
    const dialog = modalComponent.showModalMessage(dgOption, this.modalService);
    dialog.finally(()=>{
      this.focusGrid();
    })
    return dialog;
  }

  onGridReady(params: any) {
    this.gridParams = params;
    this.gridApi = params.api;
/*    this.gridApi.addEventListener("gridColumnsChanged",()=>{

    })*/
    this.columnApi = params.columnApi;

    this.suppressLoadingOverlay=false;
    this.gridApi.hideOverlay()

    if(this._isLoading && !this.task){
      this.gridApi.showLoadingOverlay();
    }
  }

  getPlanDetail(mode: DetailViewMode) {
    if(this.currentEntityView===MUCTKey.MU && this.selectedMUs.length==0){
      this.rowData = [];
      this.topRowsData = [];
      this.allRowsData = [];
      this.colDefs = [];
      return;
    }

    //If we don't have the plan details in cache, we have to trigger a background task
    this._isLoading = true;
    if(PlanHelper.isSessionExist(this.planId?.toString())){
      if(this.gridApi){
        this.gridApi.showLoadingOverlay();
      }

      let payload:any = {
        planId: this.planId,
        interval: mode,
        muRollup: this.currentEntityView===MUCTKey.MU,
        planLevel:false,
      };

      if(this.currentEntityView===MUCTKey.CT && this.selectedCTs[0].oid===PlanDetailComponentV5.ALL_CONTACT_TYPES){
        payload.planLevel=true;
      }else if(this.currentEntityView===MUCTKey.CT){
        payload.ctOids = this.selectedCTs.map(ct=>ct.oid);
      }else if(this.currentEntityView===MUCTKey.MU){
        payload.muOids = this.selectedMUs.map(mu=>mu.oid);
      }
      this.store.dispatch(new GetPlanDetailV2(payload));
    }else{
      let payload = {
        planId: this.planId,
        interval: mode,
      }
      this.store.dispatch(new LoadPlanDetailV2(payload));
    }
  }

  initHandlers() {
    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.PlanDetailReceivedV2))
        .subscribe(this.planDetailReceiveHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.PlanNotExist))
        .subscribe(this.planNotExistHandler)
        );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.EditPlanDetailReceived))
        .subscribe(this.editPlanDetailReceiveHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.EditPlanDetailError))
        .subscribe(this.editPlanDetailErrorHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SavePlanDetailComplete))
        .subscribe(this.savePlanDetailCompleteHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ConvertPlanFailed))
        .subscribe(this.convertPlanFailedHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SaveAsPlanSuccessReceived))
        .subscribe(this.saveAsSuccessHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SaveAsPlanFailed))
        .subscribe(this.saveAsFailedHandler)
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ApplyStaffingDataSuccess))
        .subscribe(this.applyStaffingDataSuccessHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ApplyMuParamsSuccess))
        .subscribe(this.applyMuParamsSuccessHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ApplyMuParamsError))
        .subscribe(this.applyMuParamsErrorHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SavePlanSettingsSuccess))
        .subscribe(this.savePlanSettingsSuccessHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.SavePlanSettingsError))
        .subscribe(this.savePlanSettingsErrorHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ApplyFTERequiredSuccess))
        .subscribe(this.applyFTERequiredSuccessHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ApplyFTERequiredError))
        .subscribe(this.applyFTERequiredErrorHandler.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ExportEveryCtSuccess))
        .subscribe(this.exportEveryCtSuccess.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.ExportEveryCtFailed))
        .subscribe(this.exportEveryCtFailed.bind(this))
    );

    this.addToSubscriptionList(
      this.action$.pipe(ofType(AppActionTypes.TaskCreateAction,
                               AppActionTypes.TaskStartAction,
                               AppActionTypes.TaskStatusAction,
                               AppActionTypes.TaskErrorAction,
                               AppActionTypes.TaskCancelAction,
                               AppActionTypes.TaskCompleteAction)
      ).subscribe(this.taskHandler.bind(this))
    );

    this.addToSubscriptionList(
        this.action$.pipe(ofType(AppActionTypes.ApplyCTSettingsSuccess))
            .subscribe(this.applyCTSettingsSuccessHandler.bind(this))
    );

  }

  onBtnExport(): void {
    const exParam: any = {
      processHeaderCallback: this.getHeaderForCsv,
      columnGroups: true,
      allColumns: true,
      skipRowGroups: true,
      fileName: this.generateCsvFileName(),
      columnSeparator: ",",
      customHeader: this.getCsvHeader(),
      processCellCallback: this.processCell,
      shouldRowBeSkipped: this.shouldSkipRow
    };
    this.gridApi.exportDataAsCsv(exParam);
  }

  private getCsvHeader() {
    const dateTime = new Date(Date.now()).toISOString();
    const exportedBy = this.translate.instant("plan.csv.exported.by");
    const exportDate = this.translate.instant("plan.csv.export.date");
    return `"${this.planName}",${exportedBy}: ${this.username},${exportDate}: ${dateTime}`;
  }

  generateCsvFileName(): string {
    const properCase: any = (p: string) => { return p.charAt(0).toUpperCase() + p.substring(1).toLowerCase() };
    const viewLevel: any = this.translate.instant("plan.viewMode." + properCase(this.gridContext.viewMode.toString()));
    const muct: string = this.translate.instant(this.currentEntityView === MUCTKey.CT ? "label.ct.short" : "label.mu.short");
    const ctIdStr = this.getCtIdStr();
    return `${this.planName} - ${muct} ${ctIdStr}- ${viewLevel}`;
  }

  getHeaderForCsv = (p: any) => {
    const colId = p.column.colId;
    let header = this.getStaticHeader(colId);
    return header ? header : p.column.colDef.headerNameFull;
  };
  private getCtIdStr() {
    const ctVal = this.contactTypeList.find(a => a.object !== null && a.object.oid === this.selectedCTs[0].oid);
    const id = ctVal ? ctVal.object.id : this.translate.instant("plan.details.cts.all");
    return this.currentEntityView == MUCTKey.CT ? "- " + id + " " : ""
  }

  getStaticHeader(colId: string): string {
    if (colId === "entityInfo") return this.translate.instant("label.mu.short")
    else if (colId === "plan_label_param") return this.translate.instant("plan.label.param");
    else if (colId === "plan_label_average") return this.translate.instant("plan.label.average");
    else if (colId === "plan_label_total") return this.translate.instant("plan.label.total");
    else return null;
  }
  processCell = (p: any) => {
    const params: any = { value: p.value, data: p.node.data, colDef: p.column.colDef };
    return this.getFormattedCellValues(params);
  };
  getFormattedCellValues(params: any) {
    // code to skip new parameters because not data.
    if (!params.data || !(params.data.metadata && params.data.metadata.cellmetadata)) {
      return "";
    }

    const cellMetadata: any = params.data.metadata.cellmetadata[params.colDef.field];
    if (cellMetadata && cellMetadata.paramStatus === PlanCellType.CLOSED)
      return "";
    else if (params.colDef.field === "entityInfo") {
      return params.data.entityInfo.value.label;
    }
    else {
      return this.cellValueFormatter(params);
    }
  }

  shouldSkipRow = (p: any) => p.node.data.isRollupHeader;

  onBtnExportEveryCt(): void {
    let interval = this.titleCasePipe.transform(this.gridContext.viewMode);
    const localizedInterval = this.translate.instant("plan.viewMode." + interval);
    const fileName = this.translate.instant("plan.exporteveryctcsv.filename", {0:this.planName, 1:localizedInterval});
    
    let payload = {
      planId: this.planId,
      interval: this.gridContext.viewMode,
      planName: this.planName,
      fileName
    };
    this._isLoading = true;
    this.gridApi.showLoadingOverlay();
    this.store.dispatch(new ExportEveryCt(payload));
  }

  exportEveryCtSuccess(action: ExportEveryCtSuccess) {
    this._isLoading = false;
    this.gridApi.hideOverlay();
  }

  exportEveryCtFailed(action: ExportEveryCtFailed) {
    this._isLoading = false;
    this.messageService.add({severity: "error", detail:this.translate.instant("unrecoverable.err.msg")});
    this.gridApi.hideOverlay();
  }

  applyCTSettingsSuccessHandler(action) {
    this._dataIsSaved = false;
  }

  planDetailReceiveHandler = (action: PlanDetailReceivedV2) => {
    this.localParams = PlanParam;
    this.isRenderingData=true;
    this._dirtyCells.clear();
    this._invalidCells.clear();

    setTimeout(()=>{
      if (action.payload) {

        let response:any = action.payload;
        this.isPlanLoaded=true;
        this.gridContext.viewMode=response.interval;
        this.planName = response.planName;
        this.planStartDate = response.settings.startDate;
        this.planEndDate = response.settings.endDate;
        this.planSettings = response.settings;
        this.entitySettings = {...response.entitySettings};

        this._userHasModify = false;
        if (response.settings && response.settings.userPlanPermission && response.settings.userPlanPermission === "MODIFY") {
          this._userHasModify = true;
        }


        this.planContactTypes = response.settings.planContactTypes;
        this.multiStepWorkflow = response.multiStepWorkFlow;
        this.planEntityType = response.planEntityType;
        this.entityId = response.settings.entityId;
        this.entityName = response.settings.entityName;
        this.ltForecastName = response.settings.ltForecastName;
        this.setEntitiesFromResponse(response);
        let colDefsTemp = this.getColumnsFromResponse(response);
        let rowDataTemp = this.getRowsFromResponse(response);
        this.topRowsData = this.getTopRowsFromResponse(response);
        this.colDefs = colDefsTemp;
        this.rowData = rowDataTemp;
        this.updateAllRowsData();


      } else { //server error
        if(this.planName && this.isPlanLoaded) {//we already have some grid data so stay on the grid
          this.messageService.add({severity: "error", detail: this.translate.instant("plan.details.interval.failure")});
        }else{
          this.planNotReceived();
        }
      }

      if(this.gridApi){
        this.gridApi.hideOverlay();
        this.gridApi.refreshHeader();
      }
      this._isLoading = false;
    },100)
  }

  planNotReceived = ()=>{
    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = this.translate.instant("plan.detail.open.failure.title");
    dgOption.messageKey = this.translate.instant("plan.detail.open.failure.msg");
    dgOption.msgType = "error";
    dgOption.showCancel = false;
    dgOption.confirmLabel = "btn.ok.label";
    var dialog = modalComponent.showModalMessage(dgOption, this.modalService);
    const navigateBack = ()=>{
      this.router.navigateByUrl("/plans");
    };
    dialog.then(navigateBack, navigateBack);
  }

  planNotExistHandler = (action: PlanNotExist) => {
    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = this.translate.instant("plan.notexist.title");
    dgOption.messageKey = this.translate.instant("plan.notexist.msg");
    dgOption.msgType = "error";
    dgOption.showCancel = false;
    dgOption.confirmLabel = "btn.ok.label";
    var dialog = modalComponent.showModalMessage(dgOption, this.modalService);
    const navigateBack = ()=>{
      this.router.navigateByUrl("/plans");
    };
    dialog.then(navigateBack, navigateBack);
  }

  editPlanDetailReceiveHandler = (action: EditPlanDetailReceived) => {
    if (action.payload) {

      this.updateExistingEntitiesRows(action);

      if(this.currentEntityView===MUCTKey.CT) {
        if (this.CTDistMode === CTDistributionCalcMethod.FTE_REQUIRED && action.payload.request.planParameterLabel != "HOURLY_RATE") {
          this.setFTERequiredDirty(true);
        }
      }

      // mu grid dirty check
      if(this.currentEntityView===MUCTKey.MU){
        this.setMUGridDirty(true);
      }

      this._dirtyCells.clear();
      this._invalidCells.clear();
      this.updateGridValid();
      // we must redraw rows since the cell-class might have changed. (Edit/Calculated)
      this.rowData=[...this.rowData];
      this.updateAllRowsData();

      this.gridApi.redrawRows();
      this._isLoading = false;
      this.gridApi.hideOverlay();
    }
    else{
      this.router.navigateByUrl("/error");
    }
  }
  updateExistingRows(updatedRows,existingRows){
    if(!updatedRows){
      return;
    }

    updatedRows.forEach((updatedRow: any) => {
      const existingRow = existingRows.find(obj => {
        return obj.metadata && obj.metadata.paramName == updatedRow.paramName;
      });
      if (existingRow) {
        Object.keys(updatedRow.data).forEach((k) => {
          const precision = Math.pow(10, updatedRow.decimal);
          let val = updatedRow.data[k];
          existingRow.metadata.cellmetadata[k] = Object.assign({}, updatedRow.metadata[k]);
          if (!existingRow.metadata.cellmetadata[k]) {
            existingRow.metadata.cellmetadata[k] = {origValue: val};
          } else {
            existingRow.metadata.cellmetadata[k].origValue = val;
          }// raw value to preserve precision
          existingRow.metadata.cellmetadata[k].workload = this.planContactTypes === PlanContactTypes.WORKLOAD;
          existingRow[k] = Number.parseFloat(val) >= 0 ? Math.round(Number(val) * precision) / precision : val;
          // existingRow[k] = Number(val)?Number(val):val;

        });

        // update total and average
        existingRow["plan_label_total"] = updatedRow.total;
        existingRow["plan_label_average"] = updatedRow.average;
      }
    });
  }
  editPlanDetailErrorHandler(action:any){
    // handle this later
    //this.router.navigateByUrl("/error");
    this._isLoading = false;
    this.messageService.add({
      severity:"error",
      detail:this.translate.instant("plan.detail.edit.failure"),
      life: this.ERROR_TOAST_LIFE
    })
    this.gridApi.hideOverlay();
  }

  savePlanDetailCompleteHandler = (action: any) => {
    this._isLoading = false;
    this.gridApi.hideOverlay();

    let errorStatus = action.payload.errorStatus;
    if (errorStatus === null) {
      this._dataIsSaved = true;
      let successMsg = this.translate.instant("plan.save.success");
      this.messageService.add({severity: "success", detail: successMsg});
    } else if (errorStatus === 409) { // plan modified
      let title = this.translate.instant("plan.save.error.title.dlg");
      let msg = this.translate.instant("plan.save.error.modified.msg");
      this.showMessage(title, msg, "error");
    } else if (errorStatus === 410) { // plan deleted
      let title = this.translate.instant("plan.save.error.title.dlg");
      let msg = this.translate.instant("plan.save.error.deleted.msg");
      this.showMessage(title, msg, "error");
    } else {
      let failureMsg = this.translate.instant("plan.save.failure");
      this.messageService.add({severity: "error", detail: failureMsg});
    }
  };

  convertPlanFailedHandler = (e: any) => {
    if (e.payload.status === 500) {
      const done = () => { };
      const dialogOpt: DialogOptions = new DialogOptions();
      dialogOpt.titleKey = "generic.err.title";
      dialogOpt.messageKey = "unrecoverable.err.msg";
      dialogOpt.msgType = "error";
      dialogOpt.confirmLabel = "btn.close";
      dialogOpt.showCancel = false;
      modalComponent.showModalMessage(dialogOpt, this.modalService).then(done, done);
      // this.messageService.add({severity: "error", detail: this.translate.instant("plan.convert.failure.general")});
    }
    else if (e.payload.status === 401) {
      // logout
      const logout = () => {
        if (this.router.navigate(["/login"]))
          this.authService.logout();
      };
      const dgOption: DialogOptions = new DialogOptions();
      dgOption.titleKey = "generic.err.title";
      dgOption.messageKey = "generic.err.msg";
      dgOption.msgType = "error";
      dgOption.confirmLabel = "btn.signin";
      dgOption.showCancel = false;
      modalComponent.showModalMessage(dgOption, this.modalService).then(logout, logout);
    }
  };

  saveAsSuccessHandler = (action: any) => {
    // set data is saved (as) so user can navigate back to managed plans page
    this._dataIsSaved = true;
    this.router.navigate(['/plans'], {queryParams: {sortBy: ColumnId.modifiedDate, sortOrder: "desc"}});
  };

  saveAsFailedHandler = async (action: any) => {
    // Toast not displayed when Save As results in a duplicate name failure and unrecoverable error
    if (![409, 500, 401].includes(action.payload.status)) {
      this.messageService.add({ severity: "error", detail: this.translate.instant("plan.save.as.failure") });
    }
  };

  protected getColumnsFromResponse(response) {

    let colDefs = [];
    if (this.translateSrv.currentLang) {
      let localeMatch = environment.locales.find(language => language.locale === this.translateSrv.currentLang);
      if (localeMatch) {
        this.dateShortFormat = localeMatch.shortDateFormat;
      }
    }



    if (response.interval === DetailViewMode.Daily) {

      // Parameters column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.param"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "left",
          headerComponent: null,
          headerName: "",
          suppressSizeToFit: true,
          width: this.pinnedLeftColumnWidth,
          field: "plan_label_param",
          //colId: "plan_label_param",
          tooltipField: "plan_label_param",
          lockPosition: true,
        }],
        headerClass: "",
        headerTooltip: this.translationStr["plan.label.param"],
        width: null
      });
      // Week/Day columns
      let weeks = response.headers;
      for (var weekKey in weeks) {
        let itemClass = "first-item";
        if (weeks.hasOwnProperty(weekKey)) {
          let children = [];
          let days = weeks[weekKey].children;
          for (let dayKey in days) {
            if (days.hasOwnProperty(dayKey)) {
              children.push({
                field: dayKey,
                //colId:dayKey,
                startDate: days[dayKey].date,
                endDate: days[dayKey].date,
                headerComponentParams: {updateViewMode: this.updateViewMode.bind(this)},
                headerComponent: null,
                headerName: this.datePipe.transform(new Date(days[dayKey].date + "T12:00:00Z"), this.dateShortFormat + "/yy"),
                headerNameFull: this.datePipe.transform(new Date(days[dayKey].date + "T12:00:00Z"), this.dateShortFormat + "/yyyy"),
                headerTooltip:this.datePipe.transform(new Date(days[dayKey].date + "T12:00:00Z"), this.dateShortFormat + "/yy"),
                suppressSizeToFit: true,
                width: 110,
                headerClass: "no-bold no-icon no-black " + itemClass,
                cellEditorSelector: this.cellEditor,
                editable: this.cellEditable,
                valueFormatter: this.cellValueFormatter,
                cellEditorParams: this.cellEditorParam,
                //onCellValueChanged: this.cellValueChanged,
                validator: ValidatorFactory.getValidator,
                valueSetter: this.cellValueSetter.bind(this),

                lockPosition:true,
              });
            }
            itemClass = "";
          }
          colDefs.push({
            headerName: this.datePipe.transform(new Date(weeks[weekKey].startDate + "T12:00:00Z"), this.dateShortFormat) +
                  "-" + this.datePipe.transform(new Date(weeks[weekKey].endDate + "T12:00:00Z"), this.dateShortFormat),
            headerNameFull: this.datePipe.transform(new Date(weeks[weekKey].startDate + "T12:00:00Z"), this.dateShortFormat+ "/yy") +
                  "-" + this.datePipe.transform(new Date(weeks[weekKey].endDate + "T12:00:00Z"), this.dateShortFormat+ "/yy"),
            headerGroupComponentFramework: PlanDetailHeaderRowComponent,
            children: children,
            headerClass: "",
            width: null,
            lockPosition:true,
          });
        }
        itemClass = "";
      }

      // Total column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.total"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "right", headerComponent: null,
          headerName: "",
          suppressSizeToFit: true,
          width: this.pinnedRightTotalColumnWidth,
          field: "plan_label_total",
          //colId: "plan_label_total",
          tooltip: this.cellValueFormatter,
          valueFormatter: this.cellValueFormatter,
          suppressMovable: true
        }],
        headerClass: "black text-right",
        headerTooltip: this.translationStr["plan.label.total"],
        width: null
      });

      // Average column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.average"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "right", headerComponent: null, suppressSizeToFit: true,
          width: this.pinnedRightColumnWidth,
          field: "plan_label_average",
          //colId: "plan_label_average",
          tooltip: this.cellValueFormatter,
          valueFormatter: this.cellValueFormatter,
          headerName: "",

          suppressMovable: true
        }],
        headerClass: "black text-right",
        headerTooltip: this.translationStr["plan.label.average"],
        width: null
      });
    }

    if (response.interval === DetailViewMode.Weekly) {

      // Parameters column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.param"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "left",
          headerComponent: null,
          headerName: "",
          suppressSizeToFit: true,
          width: this.pinnedLeftColumnWidth,
          field: "plan_label_param",
          //colId: "plan_label_param",
          tooltipField: "plan_label_param",
          lockPosition: true
        }],
        headerClass: "",
        headerTooltip: this.translationStr["plan.label.param"],
        width: null,
      });

      // Month/Week columns
      let months = response.headers;
      for (var monthKey in months) {
        let itemClass = "first-item";
        if (months.hasOwnProperty(monthKey)) {
          let children = [];
          let weeks = months[monthKey].children;
          for (var weekKey in weeks) {
            if (weeks.hasOwnProperty(weekKey)) {
              if (weeks[weekKey].partialFlag) {
                itemClass = itemClass + " partial-field";
              }
              children.push({
                field: weekKey,
                //colId: weekKey,
                startDate: weeks[weekKey].startDate,
                endDate: weeks[weekKey].endDate,
                headerComponentParams: {updateViewMode: this.updateViewMode.bind(this)},
                headerComponent: this.currentEntityView===MUCTKey.MU?null: PlanDetailClickableHeaderComponent,
                headerName: this.datePipe.transform(new Date(weeks[weekKey].startDate + "T12:00:00Z"), this.dateShortFormat) +
                  "-" + this.datePipe.transform(new Date(weeks[weekKey].endDate + "T12:00:00Z"), this.dateShortFormat),
                headerNameFull: this.datePipe.transform(new Date(weeks[weekKey].startDate + "T12:00:00Z"), this.dateShortFormat+ "/yyyy") +
                  "-" + this.datePipe.transform(new Date(weeks[weekKey].endDate + "T12:00:00Z"), this.dateShortFormat+ "/yyyy"),
                suppressSizeToFit: true,
                width: 110,
                headerClass: "no-bold no-icon no-black " + itemClass,
                cellEditorSelector: this.cellEditor,
                editable: this.cellEditable,
                valueFormatter: this.cellValueFormatter,
                cellEditorParams: this.cellEditorParam,
                //onCellValueChanged: this.cellValueChanged,
                validator: ValidatorFactory.getValidator,
                valueSetter: this.cellValueSetter.bind(this),

                lockPosition:true,
              });
            }
            itemClass = "";
          }
          colDefs.push({
            headerName: format(new Date(months[monthKey].startDate + "T12:00:00Z"), "MMM yyyy", {locale: this.dateLocale}),
            headerNameFull: format(new Date(months[monthKey].startDate + "T12:00:00Z"), "MMM yyyy", {locale: this.dateLocale}),
            headerGroupComponentFramework: PlanDetailHeaderRowComponent,
            children: children,
            headerClass: "",
            width: null,
            lockPosition:true,
          });
        }
        itemClass = "";
      }

      // Total column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.total"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "right", headerComponent: null,
          headerName: "",
          suppressSizeToFit: true,
          width: this.pinnedRightTotalColumnWidth,
          field: "plan_label_total",
          //colId: "plan_label_total",
          tooltip: this.cellValueFormatter,
          valueFormatter: this.cellValueFormatter,

          suppressMovable: true
        }],
        headerClass: "black text-right",
        headerTooltip: this.translationStr["plan.label.total"],
        width: this.pinnedRightTotalColumnWidth
      });

      // Average column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.average"],
        headerGroupComponentFramework: null,
        children: [{
          pinned: "right", headerComponent: null, suppressSizeToFit: true,
          width: this.pinnedRightColumnWidth,
          field: "plan_label_average",
          //colId: "plan_label_average",
          tooltip: this.cellValueFormatter,
          valueFormatter: this.cellValueFormatter,
          headerName: "",

          suppressMovable: true
        }],
        headerClass: "black text-right",
        headerTooltip: this.translationStr["plan.label.average"],
        width: this.pinnedRightColumnWidth
      });
    }

    if (response.interval === DetailViewMode.Monthly) {
      // Parameters column
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.param"],
        pinned: "left",
        field: "plan_label_param",
        //colId: "plan_label_param",
        tooltipField: "plan_label_param",
        width: this.pinnedLeftColumnWidth,
        suppressMovable: true,
        headerTooltip: this.translationStr["plan.label.param"]
      });
      let columns = response.headers;
      for (var columnKey in columns) {
        if (columns.hasOwnProperty(columnKey)) {

          let startDateStr = columns[columnKey].startDate;
          var startDate = new Date(startDateStr + "T12:00:00Z");
          let lastDayOfMon = this.lastday(startDate.getFullYear(), startDate.getMonth());
          let endDateStr = format(lastDayOfMon, "yyyy-MM-dd", {locale: this.dateLocale});
          let colDef: any = {
            field: columnKey,
            //colId: columnKey,
            startDate: startDateStr,
            endDate: endDateStr,
            headerComponentParams: {updateViewMode: this.updateViewMode.bind(this)},
            headerComponent: PlanDetailClickableHeaderComponent,
            headerName: format(startDate, "MMM yyyy", {locale: this.dateLocale}),
            headerNameFull: format(startDate, "MMM yyyy", {locale: this.dateLocale}),
            suppressSizeToFit: true,
            width: 110,
            lockPosition:true
          };
          colDef.cellEditorSelector = this.cellEditor;
          colDef.editable = this.cellEditable.bind(this);
          colDef.valueFormatter = this.cellValueFormatter;
          colDef.cellEditorParams = this.cellEditorParam;
          //colDef.onCellValueChanged = this.cellValueChanged;
          colDef.validator = ValidatorFactory.getValidator;
          colDef.valueSetter = this.cellValueSetter.bind(this);
          if (columns[columnKey].partialFlag) {
            colDef.headerClass = "partial-field"
          }
          colDefs.push(colDef);
        }
      }
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.total"],
        pinned: "right",
        field: "plan_label_total",
        //colId: "plan_label_total",
        tooltip: this.cellValueFormatter,
        headerClass: "black text-right",
        width: this.pinnedRightTotalColumnWidth,
        valueFormatter: this.cellValueFormatter,
        suppressMovable: true,
        headerTooltip: this.translationStr["plan.label.total"]
      });
      colDefs.push({
        headerName: null,
        headerValueGetter: () => this.translationStr["plan.label.average"],
        pinned: "right",
        field: "plan_label_average",
        //colId: "plan_label_average",
        tooltip: this.cellValueFormatter,
        valueFormatter: this.cellValueFormatter,
        headerClass: "black text-right",
        width: this.pinnedRightColumnWidth,
        suppressMovable: true,
        headerTooltip: this.translationStr["plan.label.average"]
      });
    }

    if(this.currentEntityView===MUCTKey.MU){
      colDefs.unshift({
        headerName: null,
        pinned:'left',
        headerValueGetter: () => "Entity",
        field:"entityInfo",
        keyCreator: params => {
          const entityInfo = params.data.entityInfo;
        const key = params.value?.type + "-" + entityInfo?.value.oid;
        return  key;
        },
        valueGetter: params => {
        return params.data.entityInfo?.value;
        },
        valueFormatter: param => {
          const mu = this.managementUnitsList.find(({value}) => "MU-"+value.oid == param.value);
         return mu?.value.label;
        },
        rowGroup: true, hide: true
      })
    }

    return colDefs;

  }

  public lastday = (y, m) => {
    return new Date(y, m + 1, 0);
  };

  public floor(num: any, dec: any) {
    if (num && num.toString().includes(".")) {
      var array = num.toString().split(".");
      var decPart = array.pop();
      if (!decPart) {
        decPart = "0";
      }
      array.push(decPart.substring(0, dec));
      var result = array.join(".");
      return result;
    } else {
      return num;
    }
  }

  public isPartial(params) {
    var field = params.colDef.field;
    let cellMeta = params.data?.metadata !== undefined ? params.data.metadata.cellmetadata[field] : null;
    return cellMeta && cellMeta.partialFlag;
  }

  public hasVariance(params) {
    var field = params.colDef.field;
    let cellMeta = params.data?.metadata.cellmetadata[field];
    return cellMeta && cellMeta.varianceFlag;
  }

  public setVarianceOff(params) {
    if (this.hasVariance(params)) {
      var field = params.colDef.field;
      let cellMeta = params.data?.metadata.cellmetadata[field];
      cellMeta.varianceFlag = false;
    }
  }

  cellValueSetter(params) {
    var decimal = params.data?.metadata.decimal;
    var newVal:any = params.newValue ? parseFloat(this.floor(params.newValue, decimal)) : parseFloat(params.newValue);
    var oldVal:any = params.oldValue ? parseFloat(this.floor(params.oldValue, decimal)) : parseFloat(params.oldValue);
    oldVal = isNaN(oldVal) ? "" : oldVal;

    var data = params.data;
    var result = false;

    if (newVal !== oldVal) {
      newVal = isNaN(newVal) ? "" : newVal
      if (this.hasVariance(params) && this.isManualEditing) {
        data[params.colDef.field] = newVal;
        this.confirmAndUpdate(params, oldVal);
      } else {
        data[params.colDef.field] = newVal;
        result = true;
      }

    } else {
      result = false;
    }
    return result;

  }

  confirmAndUpdate(params, oldVal) {
    let planParam: PlanParam = <PlanParam>PlanParam[params.data.metadata.paramName];
    //Parent parameter will set the warning message
    if(params.data?.metadata.parent){
      planParam = <PlanParam>PlanParam[params.data.metadata.parent];
    }
    let confMsg = ConfirmationMsgFactory.getMsg({
      paramName: planParam,
      viewMode: this.gridContext.viewMode
    });

    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = confMsg.title;
    dgOption.messageKey = confMsg.msg;
    dgOption.msgType = "warn";
    const dialog = modalComponent.showModalMessage(dgOption, this.modalService);
    dialog.then((yes) => {
      this.setVarianceOff(params);
      var refreshParam = {
        rowNodes: [params.node],
        columns: [params.column]
      };
      this.gridApi.refreshCells(refreshParam);
      this.cellValueChanged(params);
      this.focusGrid();
    }, (no) => {
      params.data[params.colDef.field] = oldVal;
      var refreshParam = {
        rowNodes: [params.node],
        columns: [params.column]
      };
      this.gridApi.refreshCells(refreshParam);
      this.rowData=[...this.rowData];//We must refresh the grid because the chart needs to revert as well
      this.updateAllRowsData();
      this.updateGridValid();
      this.focusGrid();
    });
  }

  public cellValueFormatter = (params: any) => {
    if (params && params.data && params.data.metadata) {
      let options = {decimal: params.data.metadata.decimal};
      return NumberUtils.formatNumberIfNecessary(params.value, this.locale, options.decimal);
    }
  };


  public saveButtonClicked = () => {
    this.savePlan();
  };

  private savePlan(){
    this._isLoading = true;
    this.gridApi.showLoadingOverlay();
    this.store.dispatch(new SavePlanDetail(this.planId));
  }


  public cellEditable = (params: any) => {
    let editable = false;
    let paramName: PlanParam = null;



    if(params.colDef) { //charts doesn't have col definition
      var field = params.colDef.field;
      let cellMetadata = null;
      if (params.data && params.data.metadata && params.data.metadata.cellmetadata) {
        cellMetadata = params.data.metadata.cellmetadata[field];
      }
      if (!params.data || !params.data.metadata || !params.data.metadata.paramName
        || (cellMetadata && cellMetadata.paramStatus === PlanCellType.CLOSED)) {
        return false;
      }
    }
    editable = params.data.metadata.permission===ParamPermission.Modify;

    if(this._isGridValid===false && this._dirtyCells.has(this.getCellID(params))==false){
      editable=false;
    }
    return editable;
  };

  public cellEditor = (params: any) => {
    let paramName: PlanParam = <PlanParam>PlanParam[params.data.metadata.paramName];

    if(!paramName && params.data.metadata.parent){
      paramName = <PlanParam>PlanParam[params.data.metadata.parent + "_SUB"];
    }

    var editor = {component: null, param: params};
    switch (paramName) {
      case PlanParam.ACTUAL_FTE:
      case PlanParam.SHRINKAGE:
      case PlanParam.SHRINKAGE_SUB:
      case PlanParam.ASA:
      case PlanParam.MS_EFFICIENCY:
      case PlanParam.CONTACTS:
      case PlanParam.HOURLY_RATE:
      case PlanParam.OCCUPANCY:
      case PlanParam.NEW_HIRE_FTE:
      case PlanParam.AHT:
      case PlanParam.BACKLOG:
      case PlanParam.SERVICE_LEVEL_PERCENT:
      case PlanParam.SERVICE_LEVEL_TIME:
      case PlanParam.ABS_ATTRITION:
      case PlanParam.MEAN_CONCURRENCY:
        editor.component = "numericEditor";
        break;

      default:
        break;
    }
    return editor;
  };

  public cellEditorParam = (params: any) => {
    let paramName: PlanParam = <PlanParam>PlanParam[params.data.metadata.paramName];
    const param = {
      origValue: params.data[params.colDef.field],
      metadata: params.data.metadata,
      incremental: true,
      locale: this.locale
    };
    //gets added to the editor parameter
    return param;
  };



  private setEntitiesFromResponse(response: PlanDetailResponseV2) {

    if(this.contactTypeList.length>0 || this.managementUnitsList.length>0){
      // If in the MU view, update the data model so the currently selected MU(s) is displayed
      // in the input area
      if (this.isMUView && this.muSelector) {
        this.muSelector.selectedOptions = [...this.cacheSelectedMUs];
        this.muSelector.updateModel(this.muSelector.selectedOptions);
        this.muSelector.onModelChange(this.muSelector.value);
        this.muSelector.onChange.emit({ originalEvent: null, value: this.muSelector.value });
      }
      return;
    }

    const getSelectItems = (obj, type) => {
      let item = {
        type,
        styleClass: "",
        disabled: false,
        value: {
          oid:obj.oid,
          label:"",
          type
        },
        object: obj
      };
      if (type == MUCTKey.CT) {
        item.value.label = this.translate.instant("label.ct.short") + " - " + obj.id + " " + obj.name;
      } else if (type == MUCTKey.MU) {
        item.value.label = this.translate.instant("label.mu.short") + " - " + obj.id + " " + obj.name;
      }
      return item;
    }


    const muItems: Array<any> = response.muInfo.map((mu) => getSelectItems(mu, MUCTKey.MU));
    const ctItems: Array<any> = response.ctInfo.map((ct) => getSelectItems(ct, MUCTKey.CT));


    let firstItem = null;
    if(this.multiStepWorkflow){
      let initialOid = this.getInitialCtOid();
      let initialCt = ctItems.find((item)=>item.object.oid==initialOid);
      let initialCtIndex = ctItems.indexOf(initialCt);
      ctItems.splice(initialCtIndex,1);
      firstItem = initialCt;
    }else{
      firstItem = {
        value: {
          oid:PlanDetailComponentV5.ALL_CONTACT_TYPES,
          label: this.translate.instant("plan.details.cts.all"),
          type: "ALL"
        },
        object: null,
        disabled: this._isMUDirty
      };
    }

    if (response.planEntityType != EntityType.CT || this.multiStepWorkflow) {
      ctItems.unshift(firstItem);
    } else {
      firstItem = ctItems[0];
    }

    if(ctItems.length>1){
      ctItems[0].separator = true
    }

    if (this.selectedCTs.length===0){
      this.selectedCTs = [firstItem.value];
    }

    // if(this.selectedMUs.length === 0) {
    if(this.cacheSelectedMUs.length===0){
      this.fetchMUSelectionList();
    }

    // if(this.selectedMUs.length===0 && muItems.length>0){
    if(this.cacheSelectedMUs.length===0 && muItems.length>0){
      this.selectedMUs = [muItems[0].value];
      muItems[0].isSelected = true;
      // Copying to the array object so it can be written to local storage shortly thereafter
      this.cacheSelectedMUs = [...this.selectedMUs];
      this.storeMUSelectionList();
    } else {
      this.selectedMUs = [...this.cacheSelectedMUs];
      // Do we need to write to local storage here?
      // this.storeMUSelectionList();
    }

    this.contactTypeList = ctItems;
    this.managementUnitsList = muItems;

    const selectedMUids = [];
    for(const mu of this.selectedMUs){
      selectedMUids.push(mu.oid);
    }
    this.managementUnitsList.forEach((mu) => {
      const selected = selectedMUids.indexOf(mu.value.oid) > -1;
      mu.isSelected = selected;
    });
  }
  protected getEntityInfo(oid){
    if(this.currentEntityView == MUCTKey.MU){
      return this.managementUnitsList.filter((mu)=>mu.value.oid == oid)[0]
    }else{
      return this.contactTypeList.filter((ct)=>ct.value.oid == oid)[0];
    }
  }
  protected getRowsFromResponse(response) {

    let rowGroup = 1;
    let rows = [];



    Object.keys(response.entityParams)
      .sort((oid1,oid2)=>{ return this.getMuID(oid1)-this.getMuID(oid2)})
      .forEach((entityOid,index)=>{
        Object.keys(this.localParams).map(paramKey => {
          let remoteParam = response.entityParams[entityOid].find(obj => obj.paramName == paramKey);
          let entityInfo = this.getEntityInfo(entityOid);
          let row = this.getRow(remoteParam, paramKey, rowGroup, entityInfo);
          if(remoteParam){
            rows.push(row);
          }


          rowGroup = Math.abs(rowGroup - 1);

          let remoteParamChildren = response.entityParams[entityOid].filter(obj => obj.parent == paramKey);
          if (remoteParamChildren.length > 0) {
            remoteParamChildren.forEach((remoteChild)=>{
              let childRow = this.getRow(remoteChild, remoteChild.paramName, rowGroup, entityInfo);
              rows.push(childRow);
            });
            rowGroup = Math.abs(rowGroup - 1);
          }

        });
    });
    return rows;
  }
  protected getRow(remoteParam, paramKey, rowGroup, entityInfo){
    let rowTemp = {};

    //if it's a static parameter, we have a translation for it
    if(PlanParam[paramKey]){
      // plan.detail.aht.forecast it will be plan.detail.aht.forecast
      // and plan.detail.contacts.recv will be plan.detail.contacts.recv.forecast
      if (PlanParam[paramKey] === PlanParam.CONTACTS || PlanParam[paramKey] === PlanParam.AHT) {
        rowTemp["plan_label_param"] = this.translate.instant(`${PlanParam[paramKey]}.forecast`);
      } else {
        rowTemp["plan_label_param"]=this.translate.instant(PlanParam[paramKey]);
      }
    }

    if (remoteParam && remoteParam.data) {

      let paramName: PlanParam = <PlanParam>PlanParam[paramKey];

      if(!paramName && remoteParam.parent){
        paramName = <PlanParam>PlanParam[remoteParam.parent + "_SUB"];
      }
      let validator = ValidatorFactory.getValidator(paramName, remoteParam, this.translate, this.locale);
      const rowMetaData: any = {decimal: remoteParam.decimal, paramName: paramKey, permission: remoteParam.permission, validator: validator, cellmetadata: new Map<String, any>(),};

      const precision = Math.pow(10, remoteParam.decimal);


      if (remoteParam.metadata) {
        for (let [key, value] of Object.entries(remoteParam.metadata)) {
          rowMetaData.cellmetadata[key]=Object.assign({},value);
          rowMetaData.cellmetadata[key]["minValue"] = remoteParam.minValue;
          rowMetaData.cellmetadata[key]["maxValue"] = remoteParam.maxValue;
        }
      }
      const options = this.getOptionsFromRow(remoteParam);
      for (const cellKey in remoteParam.data) {
        if (remoteParam.data.hasOwnProperty(cellKey)) {
          const val = remoteParam.data[cellKey];
          if (rowMetaData.cellmetadata[cellKey]) {
            rowMetaData.cellmetadata[cellKey].origValue = val;
          } else {
            rowMetaData.cellmetadata[cellKey]= {origValue:val};
          }// raw value to preserve precision
          rowMetaData.cellmetadata[cellKey].workload = this.planContactTypes === PlanContactTypes.WORKLOAD;
          //rowTemp[cellKey] = Number(val)!==NaN ? Math.round(Number(val) * precision) / precision : val;//this.formatNumberIfNecessary(remoteParam.data[cellKey],paramKey,options);
          rowTemp[cellKey] = Math.abs(Number.parseFloat(val)) >= 0 ? Math.round(Number(val) * precision) / precision : val;

        }
      }
      rowTemp["metadata"] = rowMetaData;
    }
    rowTemp["entityInfo"] = entityInfo;
    rowTemp["plan_label_total"] = remoteParam && remoteParam.total ? remoteParam.total : "";
    rowTemp["plan_label_average"] = remoteParam && remoteParam.average ? remoteParam.average : "";
    rowTemp["rowGroup"] = rowGroup;

    //If we're a child row
    if(remoteParam && remoteParam.parent){
      rowTemp["plan_label_param"] = remoteParam.label;
      rowTemp["rowChild"] = true;
      rowTemp["metadata"].parent = remoteParam.parent;
    }

    return rowTemp;

  }
  protected getOptionsFromRow(row) {
    var options = {
      decimal: null
    };
    if ("decimal" in row) {
      options.decimal = row.decimal;
    }
    return options;
  }


  protected  getSelectedEntityOid(){
    if(this.currentEntityView==MUCTKey.CT){
      if(this.selectedCTs.length===0 || this.selectedCTs[0].oid === PlanDetailComponentV5.ALL_CONTACT_TYPES){
        return null;
      }else{
        return this.selectedCTs[0].oid;
      }
    }else{
      return this.selectedMUs[0].oid;
    }

  }

  protected addToSubscriptionList(newSubscription: Subscription) {
    this.subscriptionList.push(newSubscription);
  }

  private clearSubscriptionList() {
    if (this.subscriptionList.length > 0) {
      this.subscriptionList.forEach(subscriptionItem => subscriptionItem.unsubscribe());
      this.subscriptionList = null;
    }
  }

  toggleChartButtonClicked(numOfCharts) {
    this.isChartView=numOfCharts>0;
    if(this.isChartView===true){
      this.chartsCount=numOfCharts
    }

  }

  public setCustomValue(row,field, value){
    var rowNode:IRowNode<any> = null;
    this.gridApi.forEachLeafNode((tableRow)=> {
      if(tableRow.data.entityInfo.value.oid === row.entityInfo.value.oid && tableRow.data.plan_label_param===row.plan_label_param){
        rowNode = tableRow;
      }
    });


    let columns = this.columnApi.getAllColumns();
    //chart is sending the field name, but ag grid generates a unique column id.
    //so we must find the right column id
    let column = columns.find((col)=>col.getColDef().field==field);
    rowNode.setDataValue(column.getColId(), value);

  }
  private shouldSave():boolean{
    return !this.authService.isTokenExpired() && !this._dataIsSaved && this._userHasModify;
  }

  async canDeactivate() {
    if(!this.shouldSave()){
      return true;
    }

    const dgOption: DialogOptions = new DialogOptions();
    dgOption.titleKey = "plan.exit.unsaved.dialog.title";
    dgOption.messageKey = "plan.exit.unsaved.dialog.msg";
    dgOption.msgType = "warn";
    dgOption.showCancel = true;
    dgOption.confirmLabel = "btn.confirm.label";
    try{
      await modalComponent.showModalMessage(dgOption, this.modalService);
      return true;
    }catch(e){
      return false;
    }
  }
  @HostListener("window:beforeunload", ["$event"])
  canCloseTab($event: any) {
    if(this.shouldSave()){
      $event.returnValue=true;
    }
  }
  @HostListener("window:unload", ["$event"])
  tabClosed($event: any){
    PlanHelper.removePlanDetailSessionId(this.planId);
  }

  private updateCurrentRoute() {

    if(this.route.snapshot.firstChild){
      this._isNestedRoute=true;
    }else{
      if(this._isNestedRoute==true){ //if we came from distribution route, we should refresh data
        this.getPlanDetail(this.gridContext.viewMode);
      }
      this._isNestedRoute=false;
    }
  }

  private navigateToDistribution(){
    this.router.navigate(["distribution"],{relativeTo: this.route, state: {saved: this._dataIsSaved}});
  }

  public canDrillDown() {
    if(this.gridContext.viewMode==DetailViewMode.Daily){
      return false;
    }else if(this.currentEntityView==MUCTKey.MU && this.gridContext.viewMode==DetailViewMode.Weekly){
      return false;
    }else{
      return true;
    }
  }
  private taskHandler(taskAction:any) {
    if (taskAction.type==AppActionTypes.TaskCompleteAction) {
      this.task = null;
    } else if(taskAction.type==AppActionTypes.TaskCancelAction) {
      this.task = null;
    }else if(taskAction.type==AppActionTypes.TaskErrorAction){
      this.task = null;
    } else { //progress
      this.task = taskAction.payload;
      if(this.task.metadata && this.task.metadata.planName){
        this.planName=this.task.metadata.planName
      }
    }
  }

  private applyStaffingDataSuccessHandler() {
    this._dataIsSaved=false;
  }

  private applyMuParamsSuccessHandler() {
    this._isLoading = false;
    this.gridApi.hideOverlay();
    this.setMUGridDirty(false);
    let successMsg = this.translate.instant("plan.detail.apply.mu.edit.success");
    this.messageService.add({severity: "success", detail: successMsg});
  }

  private applyMuParamsErrorHandler() {
    this._isLoading = false;
    this.gridApi.hideOverlay();
    let errorMsg = this.translate.instant("plan.detail.apply.mu.edit.failure");
    this.messageService.add({severity: "error", detail: errorMsg});
  }

  private savePlanSettingsSuccessHandler(){
    this._dataIsSaved = false;
    let successMsg = this.translate.instant("plan.settings.applied.successfully");
    this.messageService.add({severity: "success", detail: successMsg});
    this.getPlanDetail(this.gridContext.viewMode);
    this.setFTERequiredDirty(false);
  }
  private async savePlanSettingsErrorHandler(e){
    let payload = e.payload;
    let error = payload.error;
    let planSettings = {...payload.planSettings};

    if(error.EXCEPTION && error.EXCEPTION.Recoverable===false){
      let title = this.translate.instant("plan.detail.apply.settings.critical.title");
      let msg = this.translate.instant("plan.detail.apply.settings.critical.failure");
      try{
        await this.showMessage(title, msg, "error");
      }finally{
        this.router.navigateByUrl("/plans");
      }
    }else{
      let errorMsg = this.translate.instant("plan.detail.apply.settings.failure");
      this.messageService.add({severity: "error", detail: errorMsg});
      this._showPlanSettingsDialog(planSettings, true);
    }
    console.log(e);
  }
  private applyFTERequiredSuccessHandler(){
    this.setFTERequiredDirty(false);
    this._dataIsSaved=false;
    let successMsg = this.translate.instant("plan.detail.apply.fte.required.success");
    this.messageService.add({severity: "success", detail: successMsg});
    this.getPlanDetail(this.gridContext.viewMode);
  }
  private applyFTERequiredErrorHandler(e){
    let errorMsg = this.translate.instant("plan.detail.edit.failure");
    this.messageService.add({
      severity: "error",
      detail: errorMsg,
      life: this.ERROR_TOAST_LIFE
    });
  }
  private setFTERequiredDirty(dirty:boolean){
    this._isFTERequiredDirty=dirty;

    this.contactTypeList = this.contactTypeList.map((ctItem)=>{
      if(dirty && ctItem.type===MUCTKey.MU){
        ctItem.disabled=true;
      }else{
        ctItem.disabled=false;
      }
      return ctItem;
    });
  }

  onGridColumnsChanged($event: ModelUpdatedEvent) {
    this.scrollToTargetColumn();
  }

  private setScrollTarget(isCollapsed, column) {
    let field = column.colDef.field;
    let parts = field.split("_");

    this.scrollTargetColumn = parts[0];

    if (isCollapsed && this.gridContext.viewMode === DetailViewMode.Weekly) {
      this.scrollTargetColumn = this.scrollTargetColumn.substring(0, 6);
    }
    this.scrollTargetLeft = column.left - this.columnApi["columnModel"].scrollPosition;
  }

  private scrollToTargetColumn() {
    let targetColumn = null;
    let columns = [];

    //if we only switched CT/MU we won't have a target column
    if (!this.scrollTargetColumn) {
      this.isRenderingData = false;
      return;
    }

    //flat all columns
    this.colDefs.forEach((column) => {
      if (column.children) {
        columns.push(...column.children);
      } else {
        columns.push(column);
      }
    });


    targetColumn = columns.find((column) => column.field.startsWith(this.scrollTargetColumn));
    this.scrollTargetColumn = null;

    if (!targetColumn) {
      this.isRenderingData = false;
      return;
    }

    let gridApi: any = this.gridApi; //we're using some internal APIs so we need the :any type
    let column = this.columnApi.getColumn(targetColumn.field);
    let scrollLeft = column.getLeft() - this.scrollTargetLeft;
    if (scrollLeft < 0) {
      scrollLeft = 0;
    }

    gridApi.gridBodyCtrl.getScrollFeature().setHorizontalScrollPosition(scrollLeft); //will scroll the headers
    gridApi.ctrlsService.fakeHScrollComp.eViewport.scrollLeft = scrollLeft;  //will scroll the HTML scrollbar

    //adding some timeout to allow the scrollbar to relocate
    setTimeout(() => {
      this.isRenderingData = false;
    }, 100);
  }

  private getInitialCtOid() {
    let initialCT = this.multiStepWorkflow.cts.find((ct)=>ct.initial);
    if(initialCT){
      return initialCT.ctOid;
    }else{
      return null;
    }
  }
  initGridOptions() {
    this.gridOptions = {
      onCellEditingStarted: this.cellEditingStarted.bind(this),
      onCellEditingStopped: this.cellEditingStopped.bind(this),
      onCellValueChanged: this.cellValueChanged.bind(this),
      onPasteStart: this.onPasteStart.bind(this),
      onPasteEnd: this.onPasteEnd.bind(this),
      //processCellForClipboard:this.processCellForClipboard.bind(this),
      processDataFromClipboard: this.processDataFromClipboard.bind(this),
      processCellFromClipboard: this.processCellFromClipboard.bind(this),
      groupDisplayType: 'groupRows',
      groupDefaultExpanded: 1,
      isFullWidthRow:this.isFullWidthRow.bind(this),
      fullWidthCellRenderer:MuGroupHeaderComponentV2

    }
  }
  cellEditingStarted(params: any) {
    this.editingContext = params;
  }

  cellEditingStopped(params: any) {

  }

  cellValueChanged(cellInfo) {
    this._dataIsSaved=false;
    this._dirtyCells.add(this.getCellID(cellInfo));
    this._isPristine = false;
    this.updateGridValid();
  }

  onPasteStart(){

  }
  onPasteEnd() {

  }

  processDataFromClipboard(params) {
    let data = params.data;
    let lastRow = data[data.length - 1];
    if (data.length > 1 && lastRow.length === 1 && lastRow[0] === "") { //ms excel adds a new row with empty cell on windows
      data.pop();                           //which we need to remove before the cell parsing
    }
    data = this.duplicateClipboardToSelection(data)
    return data;
  }

  processCellFromClipboard(params) {
    return params.value;
  }

  getCellID(cellInfo) {
    if(!cellInfo.data){
      return "";
    }

    let id = cellInfo.data.entityInfo?.value.oid + "|" + cellInfo.data.metadata.paramName + "|" + cellInfo.colDef.field;
    return id;
  }

  updateGridValid() {
    let result = true;
    this._dirtyCells.forEach((cellId: string) => {
      let isValidCell = this.validateCellById(cellId);
      result = result && isValidCell;
    });

    this._isGridValid = result;
    if(result && this._dirtyCells.size>1){
      this.dispatchMultiEditRequest();
    }else if(result && this._dirtyCells.size===1){
      this.dispatchEditRequest();
    }

    this.refreshCells();
  }
  validateCellById(cellId: string) {
    let [oid, param, date] = cellId.split("|");
    let row = this.rowData.find((row) => row.entityInfo.value.oid===oid && row.metadata?.paramName===param);
    if(!row){
      return false;
    }
    let value = row[date];
    const cellMetadata = row.metadata.cellmetadata[date];
    let validator = row.metadata.validator;//ValidatorFactory.getValidator(PlanParam[param]);
    if(!validator){
      debugger;
    }
    let isValid = validator.isValid(value,cellMetadata);
    if (isValid) {
      this._invalidCells.delete(cellId);
    } else {
      this._invalidCells.add(cellId);
    }

    return isValid;
  }

  private refreshCells() {
    if (this.gridApi) {
      this.gridApi.refreshCells({force: true});
    }
  }

  private getColumnDefByField(field) {
    for (const column of this.colDefs) {
      if (column.field === field) {
        return column;
      }

      if (column.children) {
        const childColumn = column.children.find(child => child.field === field);
        if (childColumn) {
          return childColumn;
        }
      }
    }
    return null;
  }

  private getEditData(cellId){
    let [oid, param, date] = cellId.split("|");

    let row = this.rowData.find((row) => row.entityInfo.value.oid===oid && row.metadata?.paramName===param);
    var decimal = row.metadata.decimal;
    let column = this.getColumnDefByField(date)
    if(!row){
      return null;
    }
    let value = row[date] !== ""  ? Number(row[date]).toFixed(decimal) : row[date];

    const cellMetadata = row.metadata.cellmetadata[date];

    return {
      oid,
      editedValue:value,
      startDate:column.startDate,
      endDate:column.endDate,
      parameter:param,
      previousValue:cellMetadata.origValue,
    }
  }
  private dispatchMultiEditRequest() {
    this._dataIsSaved = false;
    let request: MultiEditPlanDetailRequestDTOV2 = {
      planId: this.planId,
      editInterval: this.gridContext.viewMode,
      editedData: {},
      editType: this.getEditType(),
      muRollup: this.currentEntityView===MUCTKey.MU
    }
    this._dirtyCells.forEach((dirtyCell) => this.setEditedData(request, dirtyCell));
    this.store.dispatch(new MultiEditPlanDetailV2(request));
  }

  private setEditedData(editRequest: MultiEditPlanDetailRequestDTOV2, dirtyCell:any){
    let {oid, editedValue, startDate, endDate, parameter, previousValue} = this.getEditData(dirtyCell);

    if (!editRequest.editedData[oid]) {
      editRequest.editedData[oid] = {};
    }
    if(!editRequest.editedData[oid][startDate]){
      editRequest.editedData[oid][startDate] = {
        startDate,
        endDate,
        parameters: {}
      }
    }

    editRequest.editedData[oid][startDate].parameters[parameter] = {
      previousValue,
      editedValue
    }
  }

  private dispatchEditRequest() {
    let [dirtyCell] = this._dirtyCells;
    let {oid, editedValue, startDate, endDate, parameter, previousValue} = this.getEditData(dirtyCell);

    this._isLoading = true;
    this._dataIsSaved = false;
    this.gridApi.showLoadingOverlay();
    let payload: EditPlanDetailRequestDTO = {
      planId: this.planId,
      startDate: startDate,
      endDate: endDate,
      interval: this.gridContext.viewMode,
      planParameterLabel: parameter,
      editedValue: editedValue,
      previousValue: previousValue,
      ctOid: this.currentEntityView == MUCTKey.CT ? oid == PlanDetailComponentV5.ALL_CONTACT_TYPES ? null : oid : null,
      muOid: this.currentEntityView == MUCTKey.MU ?  oid: null,
      muRollup: this.currentEntityView == MUCTKey.MU
    };
    this.store.dispatch(new EditPlanDetail(payload));

  }

  private initCellClassRules(){
    this.cellClassRules = {
      "invalid": (params) => {
        return this._isGridValid == false && this._invalidCells.has(this.getCellID(params));
      },
      "closed-field":(params)=>{
        if(!params.data?.metadata) return false;
        let cellMetadata = params.data.metadata.cellmetadata[params.colDef.field];
        if (cellMetadata && cellMetadata.paramStatus === PlanCellType.CLOSED) {
          return true;
        }
      },
      "locked": (params) => {
        if(params.colDef.field == "plan_label_average" ||
           params.colDef.field == "plan_label_total" ||
          params.colDef.field==="plan_label_param" ){
          return false;
        }
        return this._isGridValid == false && this._dirtyCells.has(this.getCellID(params)) == false;
      },
      "calculated-field":(params)=>{
        return this.isCalculatedField(params)
      },
      "cell-editable":(params)=>{
        return this.cellEditable(params);
      },
      "partial-field":(params)=>{
        return this.isPartial(params);
      },
      "negative-value":(params)=>{

        if(!params.data?.metadata) return false;
        let cellMetadata = params.data.metadata.cellmetadata[params.colDef.field];
        let cellValue = (cellMetadata && cellMetadata.origValue) ? cellMetadata.origValue : params.value;
        return NumberUtils.isNegativeNumber(cellValue);
      }
    }
  }
  initRowClassRules() {
    this.rowClassRules = {
      "editable-row":(params)=>params.data?.metadata?.permission==="MODIFY",
      "readonly-row":(params)=>params.data?.metadata?.permission==="VIEW"
    }
  }
  isCalculatedField(params){
    if(params.colDef.field == "plan_label_average" || params.colDef.field == "plan_label_total" ){
      return true;
    }

    if(!params.data?.metadata) return false;
    let cellMetadata = params.data.metadata.cellmetadata[params.colDef.field];
    return cellMetadata && cellMetadata.paramStatus === PlanCellType.CALCULATED
  }
  focusGrid(){
    let focusedCell = this.gridApi.getFocusedCell();
    if(focusedCell){
      this.gridApi.setFocusedCell(focusedCell.rowIndex,focusedCell.column);
    }
  }

  onCellEditingStarted() {
    this.isManualEditing=true;
  }

  onCellEditingStopped() {
    this.isManualEditing=false;
  }

  discard() {
    this.getPlanDetail(this.gridContext.viewMode);
    this._dirtyCells.clear();
    this._invalidCells.clear();
    this.updateGridValid();
  }

  //this method is a workaround for a bugfix with Ag-Grid.
  //Ag-Grid doesn't handle a case where you have several ranges which
  //are larger than the copied selection
  public duplicateClipboardToSelection(data: any) {
    let cellRanges = this.gridApi.getCellRanges();

    //if (cellRanges.length == 1) {
    //  return data;
    //}

    let maxDistance = cellRanges.reduce((max, cellRange) => {
      return Math.max(cellRange.endRow.rowIndex - cellRange.startRow.rowIndex, max);
    }, 0);

    while (data.length <= maxDistance) {
      data = [...data, ...data];
    }

    return data;
  }

  private getTopRowsFromResponse(response: any) {
    const grpRollupHeader = this.translate.instant("multi.mu.rollup.group.header");
    const entityInfo = {
      disabled: false,
      label: grpRollupHeader,
      object: null,
      styleClass: null,
      type: null,
      isRollup:true,
      value: {label:grpRollupHeader}
    };
    if(this.currentEntityView!==MUCTKey.MU) {
      return null;
    }
    let params = response.muRollupParams;
    let rows:any = [
      {...this.getRow(params[0],"Rollup Header",1, entityInfo),isRollupHeader:true}
    ];
    if(params){
      params.forEach((remoteParam)=>{
        rows.push(this.getRow(remoteParam,remoteParam.paramName,1, entityInfo));
      })
    }
    return rows;
  }
  private isFullWidthRow(params){
    if(params.rowNode.data?.isRollupHeader===true)
      return true;
    return false;
  }

  updateSelectedCTs($event: any) {
    this.ctSelector.updateModel([$event.itemValue.value]);
    this.ctSelector.hide();
    this.getPlanDetail(this.gridContext.viewMode);
  }

  updateSelectedMUs($event: any) {
    const selectedMUids = this.selectedMUs.map(mu => mu.oid);
    let selectedItems = [];
    this.managementUnitsList.forEach((mu) => {
      const clicked = $event.value.indexOf(mu.value) > -1;
      const previous = selectedMUids.includes(mu.value.oid);
      mu.isSelected = clicked || previous;
      if(mu.isSelected) selectedItems.push(mu.value);
    });
    this.muSelector.updateModel(selectedItems);
    this.storeMUSelectionList();
    this.shouldRefreshMUs = true;
  }

  setEntityView(view:string) {
    if(this.currentEntityView===view){
      return;
    }

    if(this.currentEntityView===MUCTKey.CT && this.gridContext.viewMode == DetailViewMode.Daily) {
        this.gridContext.viewMode = DetailViewMode.Weekly;
    }

    this.currentEntityView = MUCTKey[view];
    this.getPlanDetail(this.gridContext.viewMode);
  }

  public fetchMUSelectionList() {
    let lruCacheStr = localStorage.getItem(this.cachedMUsKey);
    if (!lruCacheStr) {
      this.initLRUCache();
    } else {
      const {selectedMUList, rollupExpanded} = this.getSelectedMUsFromCache(lruCacheStr);

      //set selected MUs and group widget state
      this.cacheSelectedMUs = selectedMUList.map((selectedMU: SelectedMU) => {
        this.muStateMap.set(selectedMU.oid, selectedMU.isExpanded);
        return selectedMU.mu;
      });

      // set rollup state
      if (this._isRollupCollapsed === rollupExpanded) {
        this.gridContext.toggleRollupGroup();
      }
    }
  }

  muStateMap = new Map();

  public getSelectedMUsFromCache(lruCacheStr: string) {
    const lruCache: LRUCache = new LRUCache();
    lruCache.init(lruCacheStr);
    let cachedMUState: CachedMU = lruCache.get(this.planId);

    //plan opened for first time (not in cache)
    if (!cachedMUState || !cachedMUState.selectedMUList) {
      cachedMUState = new CachedMU(new Array<SelectedMU>());
      lruCache.set(this.planId, cachedMUState);
    }
    return cachedMUState;
  }

  public rowGroupOpened(a:any){
    const oid = this.selectedMUs[a.node.childIndex].oid;
    this.muStateMap.set(oid, a.expanded);
    this.storeMUSelectionList();
  }

  public isGroupOpenByDefault = params => {
    if (params.key.startsWith("MU-")) {
      const muOid = params.key.substring(3);
      const expanded = this.muStateMap.get(muOid);
      return expanded;
    }
      return false;
  };


  public storeMUSelectionList(){
    let cacheStr = localStorage.getItem(this.cachedMUsKey);
    const cache:LRUCache = new LRUCache();
    if (!cacheStr) {
      cache.set(this.planId, new CachedMU(new Array<SelectedMU>()));
    } else {
      cache.init(cacheStr);
    }

    // console.log(cacheStr, this.muStateMap, this.selectedMUs);
    let selectedMUList;
    if (this.isMUView) {
      selectedMUList = this.selectedMUs.map(mu => {
        return new SelectedMU(mu.oid, mu, this.muStateMap.get(mu.oid))
      });
    } else {
      selectedMUList = this.cacheSelectedMUs.map(mu => {
        return new SelectedMU(mu.oid, mu, this.muStateMap.get(mu.oid))
      });
    }

    const cachedMUs = new CachedMU(new Array<SelectedMU>());
    cachedMUs.selectedMUList = selectedMUList;
    cachedMUs.rollupExpanded = !this._isRollupCollapsed;
    cache.set(this.planId, cachedMUs);
    localStorage.setItem(this.cachedMUsKey, cache.asString());
    this.fetchMUSelectionList();
  }

  public clearSelectedMUs(){
    this.selectedMUs = [];
  }

  muSelectorClosed() {
    if(this.shouldRefreshMUs){
      this.getPlanDetail(this.gridContext.viewMode);
      this.shouldRefreshMUs = false;
    }
  }
  getMuID(oid){
    return this.managementUnitsList.find((mu)=>mu.object.oid===oid).object.id;
  }
  getEditType(){
    if(this.currentEntityView == MUCTKey.MU){
      return MUCTKey.MU;
    }else if(this.currentEntityView == MUCTKey.CT){
      if(this.selectedCTs[0].oid===PlanDetailComponentV5.ALL_CONTACT_TYPES){
        return "ALL";
      }else{
        return MUCTKey.CT;
      }
    }
  }

  private updateExistingEntitiesRows(response:EditPlanDetailReceived) {
    const { muRollupParams, editValues, editedEntities, request } = response.payload;

    if(muRollupParams){ //Mu Rollup
      this.updateExistingRows(response.payload.muRollupParams,this.topRowsData);
    }
    if(editValues){ //Single cell editing.
        let entityOid = this.getEntityOidFromRequest(request);
        let entityRows = this.rowData.filter((row)=>row.entityInfo.value.oid===entityOid);
        this.updateExistingRows(response.payload.editValues,entityRows);
    }
    if(editedEntities){ //When doing copy/paste we have a dictionary of entities
      let editedEntities = response.payload.editedEntities;
      Object.keys(editedEntities).forEach((oid)=>{
        let editedEntityParams = editedEntities[oid];
        let entityRows = this.rowData.filter((row)=>row.entityInfo.value.oid===oid);
        this.updateExistingRows(editedEntityParams,entityRows);
      })
    }
  }

  private getEntityOidFromRequest(request) {
    return request.ctOid || request.muOid || request.entityOid || PlanDetailComponentV5.ALL_CONTACT_TYPES;
  }

  private updateAllRowsData() {
    if(this.topRowsData){
      this.allRowsData = [...this.topRowsData,...this.rowData];
    }else{
      this.allRowsData = [...this.rowData];
    }

  }

  navigateToPlanCtSettings() {
    this.router.navigate(["ct-settings"], {
      relativeTo: this.route,
      state: {
        ct: this.getSelectedCtID(),
        saved: this._dataIsSaved
      }
    });
  }

  getPlanSettingsText() {
    if (this._planCtSettingsFeature) {
      return this.translateSrv.instant("plan.settings.full");
    } else {
      return this.translateSrv.instant("plan.settings");
    }
  }
  getSelectedCtID(){
    if(this.currentEntityView==MUCTKey.CT){
      return this.selectedCTs[0].oid;
    }
    return null;
  }

}

export enum MUCTKey {
  MU = "MU", CT = "CT"
}

